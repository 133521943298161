<template>
  <div class="main">
    <!-- pc端 -->
    <div
      class="main-pc"
      v-if="drawershow == 'true'"
    >
      <div class="main1 main-box">
        <div class="main1-content">
          <div>
            <span>{{ name }}</span>&nbsp;&nbsp;&nbsp;
            <span>{{ main1.subTitle }}</span>
          </div>
          <div>
            <span>
              <router-link to="/">首页</router-link>&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;
            </span>
            <span>{{ name }}</span>
          </div>
        </div>
      </div>
      <div class="main2 main-box">
        <el-tabs
          :tab-position="tabPosition"
          v-model="activeName"
        >
          <el-tab-pane
            :label="tab1.title"
            name="41"
          >
            <div v-html="subText"></div>
          </el-tab-pane>

          <el-tab-pane
            :label="tab2.title"
            name="42"
          >
            <div v-html="subText"></div>
          </el-tab-pane>
          <el-tab-pane
            :label="tab3.title"
            name="43"
          >
            <div v-html="subText"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="main3 main-box">
        <div class="main3-content">
          <p>{{ main3.title }}</p>
          <p>
            {{ main3.titleText }}
          </p>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div
      class="main-mobile"
      v-else
    >
      <div class="main2 main-box">
        <el-tabs
          :tab-position="tabPosition1"
          v-model="activeName"
        >
          <el-tab-pane
            :label="tab1.title"
            name="41"
          >
            <div v-html="subText"></div>
          </el-tab-pane>

          <el-tab-pane
            :label="tab2.title"
            name="42"
          >
            <div v-html="subText"></div>
          </el-tab-pane>
          <el-tab-pane
            :label="tab3.title"
            name="43"
          >
            <div v-html="subText"></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { getOne } from "@/api/policy";

export default {
  props: ["name"],
  data () {
    return {
      activeName: "CA 数字证书", //tab的选择
      tabPosition: "left",
      tabPosition1: "top",
      subText: "",
      main1: {
        title: "产品创新",
        subTitle: "PRODUCT INNOVATION",
      },
      tab1: {
        //img1: require("../../assets/img/组 2-1.png"),
        title: "CA 数字证书",
      },
      tab2: {
        // img1: require("../../assets/img/组 2-2.png"),
        title: "软件开发服务",
      },
      tab3: {
        // img1: require("../../assets/img/组 2-3.png"),
        title: "区块链技术",
      },
      main3: {
        title: "致电400-105-1009获取您的专属解决方案",
        titleText:
          " 丰富的行业纵深，无论您的企业处在什么样的发展阶段，都可以获得相应的方案设计",
      },
      drawershow: sessionStorage.getItem("drawershow"), //是否打开pc端样式
    };
  },
  beforeCreate () {
    console.log(sessionStorage.getItem("home2"), "111145454");
  },
  created () {
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });

    this.activeName = this.$route.query.title;
    if (
      sessionStorage.getItem("home2") == null ||
      this.$route.query.title == undefined
    ) {
      // console.log(555555555555);
      this.activeName = "41";
    } else {
      this.activeName = sessionStorage.getItem("home2");
    }
  },
  mounted () {
    console.log(this.$route.query.title, "1111111111111111");
  },
  watch: {
    activeName: function (val) {
      //监听切换状态-计划单
      var that = this;
      getOne(val).then((res) => {
        console.log(1121);
        that.subText = res.data.content;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main-pc {
  width: 100%;
  height: auto;
  background: #fff;
  // overflow: hidden;
  // display: flex;
  .main-box {
    width: 60.2rem;
    margin: auto;
  }
  .main1 {
    width: 100%;
    height: 6rem;
    // background: red;
    background: #f8f8f8;
    .main1-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      background: #f8f8f8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(1) {
        span:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 550;
          letter-spacing: 1px;
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          letter-spacing: 1px;
        }
      }
      div:nth-child(2) {
        span:nth-child(1) {
          font-size: 0.7rem;
          letter-spacing: 1px;
          a {
            color: black;
          }
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          font-weight: 550;
          color: #0b4696;
          letter-spacing: 1px;
        }
      }
    }
  }
  .main2 {
    width: 60.2rem;
    padding-top: 2.8rem;
    padding-bottom: 0.75rem;
    background: #fff;
    /deep/ .el-tab-pane {
      // background: #848484;
      p {
        color: rgba(132, 132, 132, 1);
      }
    }
    /deep/.el-tabs--left .el-tabs__item.is-left {
      border: 1px solid #e5e5e5;
      width: 16.5rem;
      height: 3rem;
      margin-bottom: 0.25rem;
      line-height: 3rem;
      text-align: left;
      margin-right: 1.25rem;
      font-size: 0.7rem;
      user-select: none;
    }
    /deep/.el-tabs--left .el-tabs__item.is-left:after {
      width: 1rem;
      height: 1rem;
      content: "\e63e";
      font-family: "iconfont";
      border-radius: 50%;
      display: inline-block;
      float: right;
      line-height: 0.9rem;
      text-align: center;
      margin-top: 1rem;
      font-size: 0.9rem;
    }
    /deep/.el-tabs__item.is-active {
      background: rgba(11, 70, 150, 1);
      color: #fff;
    }

    /deep/.el-tabs__nav-wrap::after {
      background-color: rgba(255, 255, 255, 0);
    }
    /deep/.el-tabs__active-bar {
      background-color: rgba(255, 255, 255, 0);
    }
    img {
      width: 100%;
    }
    h1 {
      font-size: 1.2rem;
      line-height: 4rem;
      // font-weight: 550;
    }
    // p {
    //   line-height: 50px;
    //   color: rgba(132, 132, 132, 1);
    //   overflow: hidden;
    //   .image {
    //     width: 300px;
    //     float: left;
    //     margin-top: 30px;
    //     margin-right: 10px;
    //   }
    //   span {
    //     margin-top: 20px;
    //     display: block;

    //     h3 {
    //       line-height: 30px;
    //       font-size: 14px;
    //       font-weight: 550;
    //     }
    //     p {
    //       font-size: 14px;
    //       line-height: 25px;
    //     }
    //   }
    // }
  }

  .main3 {
    height: 11rem;
    width: 100%;
    background: #f8f8f8;
    .main3-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      p:nth-child(1) {
        font-size: 1.5rem;
        // font-weight: 600;
        // line-height: 4vw;
      }
      p:nth-child(2) {
        font-size: 0.8rem;
        letter-spacing: 0.2rem;
        margin-top: 1rem;
        color: #848484;
      }
    }
  }
}
.main-mobile {
  height: auto;
  margin-bottom: 5rem;
  background: #fff;
  padding: 1rem 2rem;
  // .title {
  //   width: 100%;
  //   height: 45vw;
  //   background: url("../../assets/img/mobile1-1.png") no-repeat;
  //   background-size: 100%;

  // border: 1px solid black;
  .main2 {
    width: 100%;

    padding-bottom: 0.75rem;

    /deep/.el-tabs--top {
      .el-tabs__content {
      }
    }

    /deep/ .el-tab-pane {
      // background: #848484;
      p {
        color: rgba(132, 132, 132, 1);
      }
    }

    /deep/.el-tabs__nav-wrap::after {
      background-color: rgba(255, 255, 255, 0);
    }
    /deep/.el-tabs__active-bar {
      background-color: rgba(255, 255, 255, 0);
    }
    img {
      width: 100%;
    }
    h1 {
      font-size: 1.2rem;
      line-height: 4rem;
      // font-weight: 550;
    }
    p {
      line-height: 50px;
      color: rgba(132, 132, 132, 1);
      overflow: hidden;
      .image {
        width: 300px;
        float: left;
        margin-top: 30px;
        margin-right: 10px;
      }
      span {
        margin-top: 20px;
        display: block;

        h3 {
          line-height: 30px;
          font-size: 14px;
          font-weight: 550;
        }
        p {
          font-size: 14px;
          line-height: 25px;
        }
      }
    }
  }
  // }
}
</style>
