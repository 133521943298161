<template>
  <div class="nav">
    <div
      class="nav-pc"
      v-if="drawershow == 'true'"
    >
      <router-link to="/">
        <img
          :src="logo"
          alt=""
          style="height: 42px"
        />
      </router-link>

      <ul>
        <router-link
          v-for="(item, index) in listObj"
          :key="index"
          :to="item.router"
        >
          <li
            @click="tabClick(item)"
            :class="$store.state.nav === index ? 'active' : ''"
          >
            <!-- 2021/11/18晚上研究的代码 -->
            {{ item.nav }}
          </li>
        </router-link>
      </ul>
    </div>
    <div
      class="nav-mobile"
      v-else
    >
      <router-link to="/">
        <div class="img"></div>
      </router-link>

      <el-button
        @click="drawer = true"
        type="primary"
        style="margin-left: 16px; background: none; border: none"
      >
        <div class="icon"></div>
      </el-button>
      <el-drawer
        title="我是标题"
        :visible.sync="drawer"
        :with-header="false"
      >
        <router-link
          v-for="(item, index) in listObjMobile"
          :key="index"
          :to="item.router"
        >
          <div
            @click="tabClickMobile(item)"
            :class="current === index ? 'active' : ''"
          >
            {{ item.nav }}
          </div>
        </router-link>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  // props: ["drawershow"], //是否打开pc端样式
  data () {
    return {
      //////////////////////////////////////////////////// pc端
      show: false,
      screenWidth: document.documentElement.clientWidth,
      logo: require("../../assets/img/shnm-logo-top.svg"),
      listObj: [
        {
          id: 0,
          nav: "首页",
          router: "/",
        },
        {
          id: 1,
          nav: "产品创新",
          router: "/home/home2",
        },
        {
          id: 2,
          nav: "服务支持",
          router: "/home/home3",
        },
        {
          id: 3,
          nav: "新闻中心",
          router: "/home/home6",
        },
        {
          id: 4,
          nav: "关于我们",
          router: "/home/home4",
        },
        {
          id: 5,
          nav: "人才招聘",
          router: "/home/home7",
        },
        {
          id: 6,
          nav: "联系我们",
          router: "/home/home5",
        },
      ],
      // name: "",
      //////////////////////////////////////////////////////////
      //////////////////////////////////////////////////// 手机端
      drawer: false,
      current: 0, //为开始显示哪一个定调
      listObjMobile: [
        {
          id: 0,
          nav: "首页",
          router: "/",
        },
        {
          id: 1,
          nav: "产品创新",
          router: "/Home/Home2/",
        },
        {
          id: 2,
          nav: "服务支持",
          router: "/Home/Home3/",
        },
        {
          id: 3,
          nav: "关于我们",
          router: "/home/home4",
        },
        {
          id: 4,
          nav: "新闻中心",
          router: "/home/home6",
        },
        {
          id: 5,
          nav: "人才招聘",
          router: "/home/home7",
        },
        {
          id: 6,
          nav: "联系我们",
          router: "/home/home5",
        },
      ],
      drawershow: sessionStorage.getItem("drawershow"),
      //////////////////////////////////////////////////////////
    };
  },
  mounted () {
    this.changeNav(); //2021/11/18晚上研究的代码
  },
  created () {
    // this.name = this.$route.name;

    // console.log(this.name, 121211211);
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });
  },
  methods: {
    tabClick (item) {
      // console.log(item);
      this.$store.state.nav = item.id;
    },
    changeNav () {
      // console.log(88888888);
      // this.listObj.map((item) => {
      //   //2021/11/18晚上研究的代码
      //   if (item.nav == this.$route.name) {
      //     this.$store.state.nav = item.id;
      //   }
      // });
    },
    tabClickMobile (item) {
      console.log(item.id);
      this.current = item.id;
      this.drawer = false;
    },
  },
};
</script>

<style lang="less" scoped>
.nav {
  width: 100%;
  height: 80px;
  background: #fff;
  // pc端的样式
  .nav-pc {
    width: 60.25rem;
    height: 100%;
    // background: wheat;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    user-select: none;
    ul {
      width: 800px;
      height: 100%;
      margin: 0;
      li {
        width: 8%;
        height: 100%;
        list-style: none;
        float: left;
        font-size: 14px;
        line-height: 80px;
        font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
        user-select: none;
        color: black;
        text-align: right;
        margin-left: 6.25%;
      }
      .active {
        color: #0f4997;
        // background: cornsilk;
      }
    }
  }
  //手机端的样式
  .nav-mobile {
    width: 100%;
    height: 65px;
    background: #fff;
    // background: gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0rem 1rem 0rem 1rem;
    .img {
      height: 2.5rem;
      width: 10rem;
      // background: #0f4997;
      background: url("../../assets/img/shnm-logo-top.svg") no-repeat;
      background-size: 100%;
      margin-top: 0.3rem;
    }
    .icon {
      width: 1.9rem;
      height: 1.1rem;
      background: url("../../assets/img/min-icon1-1.png") no-repeat;
      background-size: 100% 100%;
      // background: red;
    }
    /deep/.el-drawer__body {
      background: #fff;
      div {
        width: 60%;
        height: 3.125rem;
        border-bottom: 1px solid rgba(0, 0, 0, 0.055);
        text-align: center;
        line-height: 3.125rem;
        font-size: 0.9rem;
        margin: auto;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.349);
        letter-spacing: 0.1rem;
      }
      .active {
        width: 80%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.247);
        transition: 0.9s;
        font-weight: 550;
        color: rgba(0, 0, 0, 0.678);
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .nav {
    transition: all 0.5s linear;
    height: 65px;
  }
}
</style>
