<template>
  <div class="home1">
    <Section2 />
    <Main />
    <!-- :data="name" -->
  </div>
</template>

<script>
import Section2 from "../components/Home/Section2.vue";
import Main from "../components/Home/Main.vue";
import store from "../store";
export default {
  // name: "Home1",
  data () {
    return {
      // name: "1",
    };
  },
  beforeCreate () {
    const that = this;
    if (sessionStorage.getItem("drawershow")) {
      //当他有值的时候
      console.log(1);
    } else {
      //当他没有值的时候
      window.screenWidth = document.documentElement.clientWidth;
      if (window.screenWidth >= 992) {
        this.resetSetItem("drawershow", true);

        store.commit("SET_DRAWERSHOW", true);
      } else if (that.screenWidth < 992) {
        this.resetSetItem("drawershow", false);

        store.commit("SET_DRAWERSHOW", false);
      }
    }
  },
  components: {
    Section2,
    Main,
  },
  mounted () {
    // console.log(this.$route, "1");
    this.$store.state.nav = 0; //2021/11/18晚上研究的代码
  },
};
</script>
