<template>
  <div class="contact-us">
    <Main :name="name" />
  </div>
</template>

<script>
import Main from "../components/Home5/Main.vue";

export default {
  // name: "Home5",
  data () {
    return {
      name: "6",
    };
  },
  components: {
    Main,
  },
  mounted () {
    console.log(this.$route.name, "6 ");
    this.name = this.$route.name;
  },
};
</script>

<style></style>
