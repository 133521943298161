<template>
  <!-- 这里使用了rem  标记 -->
  <div class="section">
    <el-container>
      <el-main>
        <img
          :src="bigpic"
          alt=""
        />
        <div class="text">
          <div v-html="reson"></div>

          <el-button @click="goto()">立即查看业务详情</el-button>
        </div>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import { getOne } from "@/api/policy";

export default {
  data () {
    return {
      bigpic: "",
      reson: "",
      link: "",
      drawershow: sessionStorage.getItem("drawershow"), //是否打开pc端样式
    };
  },
  created () {
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });

    var that = this;
    getOne(1).then((res) => {
      that.bigpic = res.data.tiltePic;
      that.reson = res.data.content;
      that.link = res.data.link;
    });
  },
  methods: {
    goto () {
      if (this.drawershow == "true") {
        this.$router.push("/home/home2");
      } else {
        this.$router.push("/Home/Home2/");
      }

      // location.href = this.link;
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  width: 100%;
  height: auto;
  background: black;
  padding: 0;
  overflow: hidden;
  display: flex;
  position: relative;
  img {
    width: 100%;
    min-width: 60.25rem;
    // display: flex;
  }
  /deep/.text {
    width: 60.25rem;
    height: 45%;
    margin: auto;
    // background: blanchedalmond;
    // border: 1px solid black;
    position: absolute;
    top: -4rem;
    bottom: 0;
    left: 0.7rem;
    right: 0;
    p {
      color: #fff;
      // p {
      //   margin-bottom: 10px;
      // }
    }
    p:nth-child(1) {
      // height: 10%;
      font-size: 1.2rem;
      letter-spacing: 0.5rem;
      font-weight: 300;
      // background: red;
    }
    p:nth-child(2) {
      font-size: 1.75rem;
      font-weight: 550;
      letter-spacing: 0.3rem;
    }
    p:nth-child(3),
    p:nth-child(4) {
      font-size: 0.75rem;
      font-weight: 200;
      letter-spacing: 0.2rem;
    }
    .el-button {
      margin-top: 2rem;
      background: #0b4696;
      color: #fff;
      border: 1px solid #0b4696;
      border-radius: 0px;
      font-size: 0.6rem;
      font-weight: normal;
      letter-spacing: 0.2rem;
    }
  }
}
</style>
