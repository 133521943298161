<template>
  <div id="app">
    <v-touch
      v-on:swipeleft="onSwipeLeft"
      v-on:swiperight="onSwipeRight"
    >
      <div class="content"></div>
    </v-touch>
    <router-view />
  </div>
</template>
<script>
import store from "../src/store";
export default {
  beforeCreate () {
    const that = this;
    if (sessionStorage.getItem("drawershow")) {
      //当他有值的时候
      console.log(1);
    } else {
      //当他没有值的时候
      window.screenWidth = document.documentElement.clientWidth;
      if (window.screenWidth >= 992) {
        this.resetSetItem("drawershow", true);
        sessionStorage.setItem("drawershow", true);
        store.commit("SET_DRAWERSHOW", true);
      } else if (that.screenWidth < 992) {
        this.resetSetItem("drawershow", false);
        sessionStorage.setItem("drawershow", false);
        store.commit("SET_DRAWERSHOW", false);
      }
    }
  },
  // name: "app",
  // name: "",
  methods: {
    onSwipeLeft: function () {
      //左滑动
      //写入向左滑触发事件
      //例如跳转页面
      console.log("left");
    },
    onSwipeRight: function () {
      //右滑动
      console.log("right");
    },
  },
};
</script>
<style lang="less">
body {
  background-color: #ffffff;
  -webkit-overflow-scrolling: touch;
}
</style>
