<template>
  <div class="main">
    <!-- pc端 -->
    <div
      class="main-pc"
      v-if="drawershow == 'true'"
    >
      <div class="main1 main-box">
        <div class="main1-content">
          <div>
            <span>{{ name }}</span>&nbsp;&nbsp;&nbsp;
            <span>{{ main1.subTitle }}</span>
          </div>
          <div>
            <span>
              <router-link to="/">首页</router-link>&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;
            </span>
            <span>{{ name }}</span>
          </div>
        </div>
      </div>
      <div class="main2 main-box">
        <el-tabs
          :tab-position="tabPosition"
          v-model="activeName"
        >
          <el-tab-pane
            :label="tab1.title"
            name="52"
          >
            <!-- <img :src="tab1.img1" alt="" /> -->
            <!-- <h1>{{ tab1.subTitle1 }}</h1> -->
            <div
              v-html="subText"
              class="rich"
            ></div>
          </el-tab-pane>
          <el-tab-pane
            :label="tab2.subTitle1"
            name="51"
          >
            <!-- <img :src="tab2.img1" alt="" /> -->
            <!-- <h1>{{ tab2.subTitle1 }}</h1> -->
            <div
              v-html="subText"
              class="rich"
            ></div>
          </el-tab-pane>
          <el-tab-pane
            :label="tab3.subTitle1"
            name="53"
          >
            <!-- <img :src="tab3.img1" alt="" /> -->
            <!-- <h1>{{ tab3.subTitle1 }}</h1> -->
            <div
              v-html="subText"
              class="rich"
            ></div>
          </el-tab-pane>
          <el-tab-pane
            :label="tab4.title"
            name="54"
          >
            <!-- <img :src="tab4.img1" alt="" /> -->
            <!-- <h1>{{ tab4.subTitle1 }}</h1> -->
            <div
              v-html="subText"
              class="rich"
            ></div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <div class="main3 main-box">
        <div class="main3-content">
          <p>{{ main3.title }}</p>
          <p>
            {{ main3.titleText }}
          </p>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div
      class="main-mobile"
      v-else
    >
      <div class="main2 main-box">
        <el-tabs
          :tab-position="tabPosition1"
          v-model="activeName"
        >
          <el-tab-pane
            :label="tab1.title"
            name="52"
          >
            <!-- <img :src="tab1.img1" alt="" /> -->
            <!-- <h1>{{ tab1.subTitle1 }}</h1> -->
            <div
              v-html="subText"
              class="rich"
            ></div>
          </el-tab-pane>
          <el-tab-pane
            :label="tab2.subTitle1"
            name="51"
          >
            <!-- <img :src="tab2.img1" alt="" /> -->
            <!-- <h1>{{ tab2.subTitle1 }}</h1> -->
            <div
              v-html="subText"
              class="rich"
            ></div>
          </el-tab-pane>
          <el-tab-pane
            :label="tab3.subTitle1"
            name="53"
          >
            <!-- <img :src="tab3.img1" alt="" /> -->
            <!-- <h1>{{ tab3.subTitle1 }}</h1> -->
            <div
              v-html="subText"
              class="rich"
            ></div>
          </el-tab-pane>
          <el-tab-pane
            :label="tab4.title"
            name="54"
          >
            <!-- <img :src="tab4.img1" alt="" /> -->
            <!-- <h1>{{ tab4.subTitle1 }}</h1> -->
            <div
              v-html="subText"
              class="rich"
            ></div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { getOne } from "@/api/policy";
import store from "../../store";
export default {
  props: ["name"],
  data () {
    return {
      subText: "",
      activeName: "服务目标", //tab的选择
      tabPosition: "left",
      tabPosition1: "top",
      main1: {
        title: "服务支持",
        subTitle: "SERVICE SUPPORT",
      },
      tab1: {
        // img1: require("../../assets/img/组 1-1.png"),
        title: "服务目标",
        subTitle1: "服务目标",
        subText1:
          "宁明软件拥有专业的技术服务队伍，服务人员均具备大型企业的技术背景，能为客户提供过硬、优质的技术保障。<br />实行区域管理，就近响应，可快速响应客户的需求；<br />可结合客户的需求，为客户做针对性的定制化解决方案；<br />有完善的年度服务报告，为客户提供更贴心，更周到的服务；<br />对各种产品、流程的规范培训，鼓励员工不断学习深造，来保证一流的服务品质，打造高效的技术客服团队。",
      },
      tab2: {
        //  img1: require("../../assets/img/组 1-2.png"),
        title: "服务体系",
        subTitle1: "服务体系",
        subText1:
          "宁明软件始终将客户服务作为工作的重点，让客户真正感受到毅成软件科技是值得合作的企业。<br /><span><h3>服务管理:</h3><p>· 标准化：采用统一的服务流程；<br />· 体系化：建立完善的服务体系，来确保能够第一时间响应客户的服务需求；<br />· 自主化：主动了解用户产品的使用反馈。</p></span><span><h3>服务方式:</h3><p>· 通过电话、Email、在线客服支持;<br />· 回馈工单：可在线提交产品的任何使用问题，客服将回复和联系用户；<br />· 可以安排客服上门服务。</p></span><span><h3>服务内容：</h3><p>· 对产品使用的技术咨询；<br />· 提供产品的技术资料及软件；<br />· 提供对软件产品的使用培训;<br />· 提供产品的维护和升级；</p></span>",
      },
      tab3: {
        // img1: require("../../assets/img/组 1-3.png"),
        title: "服务网络",
        subTitle1: "服务网络",
        subText1:
          "宁明软件业务覆盖全国大多数省市区域，形成了以长江三角洲为中心辐射全国的运营服务体系。",
      },
      tab4: {
        // img1: require("../../assets/img/组 1-4.png"),
        title: "技术支持",
        subTitle1: "技术支持",
        subText1:
          "宁明软件的服务团队致力于保障客户产品的稳定高效运行。我们的服务工程师和临床应用专家将帮助您安置和培训操作人员，保证系统正常运行。<br />如果遇到问题需要帮助,我们的团队会第一时间帮您解决。<br /><span><h3>电话支持</h3><p>宁明软件为您提供即时的电话协助，快速就地解决您的问题。</p></span><span><h3>远程服务</h3><p>宁明软件为您提供远程服务，无需登门即可快速诊断系统问题（即查看图像、下载系统图像和错误日志)。这样可以实现问题的早期检测，将故障停机几率降至最任。</p></span><span><h3>升级服务</h3><p>宁明软件免费提供基础软件升级服务，保持设备始终处于最新状态，延长您的初始投资周期。我们也可提供硬件升级，需视情收费。</p></span>",
      },
      main3: {
        title: "致电400-105-1009获取您的专属解决方案",
        titleText:
          " 丰富的行业纵深，无论您的企业处在什么样的发展阶段，都可以获得相应的方案设计",
      },
      drawershow: sessionStorage.getItem("drawershow"), //是否打开pc端样式
    };
  },
  beforeCreate () {
    const that = this;
    if (sessionStorage.getItem("drawershow")) {
      //当他有值的时候
      console.log(1);
    } else {
      //当他没有值的时候
      window.screenWidth = document.documentElement.clientWidth;
      if (window.screenWidth >= 992) {
        this.resetSetItem("drawershow", true);

        store.commit("SET_DRAWERSHOW", true);
      } else if (that.screenWidth < 992) {
        this.resetSetItem("drawershow", false);

        store.commit("SET_DRAWERSHOW", false);
      }
    }
  },
  created () {
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });

    if (
      sessionStorage.getItem("home3") == null ||
      this.$route.query.title == undefined
    ) {
      this.activeName = "52";
    } else {
      this.activeName = sessionStorage.getItem("home3");
    }
  },
  watch: {
    activeName: function (val) {
      //监听切换状态-计划单
      var that = this;
      getOne(val).then((res) => {
        that.subText = res.data.content;
      });
    },
  },
};
</script>

<style lang="less" scoped>
.main-pc {
  width: 100%;
  height: auto;
  background: #fff;
  .main-box {
    width: 60.2rem;
    margin: auto;
  }
  .main1 {
    width: 100%;
    height: 6rem;
    background: #f8f8f8;
    .main1-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      background: #f8f8f8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(1) {
        span:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 550;
          letter-spacing: 1px;
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          letter-spacing: 1px;
        }
      }
      div:nth-child(2) {
        span:nth-child(1) {
          font-size: 0.7rem;
          letter-spacing: 1px;
          a {
            color: black;
          }
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          font-weight: 550;
          color: #0b4696;
          letter-spacing: 1px;
        }
      }
    }
  }
  .main2 {
    width: 60.2rem;
    padding-top: 2.8rem;
    padding-bottom: 0.75rem;
    background: #fff;
    /deep/ .el-tab-pane {
      // background: #848484;
      p {
        color: rgba(132, 132, 132, 1);
      }
    }
    /deep/.el-tabs--left .el-tabs__item.is-left {
      border: 1px solid #e5e5e5;
      width: 16.5rem;
      height: 3rem;
      margin-bottom: 0.25rem;
      line-height: 3rem;
      text-align: left;
      margin-right: 1.25rem;
      font-size: 0.7rem;
      user-select: none;
    }
    // /deep/.el-tabs__content {
    //   background: #0b4696;
    //   height: 100%;
    // }
    /deep/.el-tabs--left .el-tabs__item.is-left:after {
      width: 1rem;
      height: 1rem;
      content: "\e63e";
      font-family: "iconfont";
      border-radius: 50%;
      display: inline-block;
      float: right;
      line-height: 0.9rem;
      text-align: center;
      margin-top: 1rem;
      font-size: 0.9rem;
    }
    /deep/.el-tabs__item.is-active {
      background: rgba(11, 70, 150, 1);
      color: #fff;
    }

    /deep/.el-tabs__nav-wrap::after {
      background-color: rgba(255, 255, 255, 0);
    }
    /deep/.el-tabs__active-bar {
      background-color: rgba(255, 255, 255, 0);
    }
    img {
      width: 100%;
    }
    h1 {
      font-size: 1.2rem;
      line-height: 4rem;
      // font-weight: 550;
    }
    .rich {
      // color: rgba(132, 132, 132, 1);
    }
  }

  .main3 {
    height: 11rem;
    width: 100%;
    background: #f8f8f8;
    .main3-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      user-select: none;
      p:nth-child(1) {
        font-size: 1.5rem;

        // font-weight: 600;
        // line-height: 4vw;
      }
      p:nth-child(2) {
        font-size: 0.8rem;
        letter-spacing: 0.2rem;
        margin-top: 1rem;
        color: #848484;
      }
    }
  }
}
.main-mobile {
  height: auto;
  margin-bottom: 5rem;
  background: #fff;
  padding: 1rem 2rem;
  .main2 {
    width: 100%;

    padding-bottom: 0.75rem;

    /deep/.el-tabs--top {
      .el-tabs__content {
      }
    }

    /deep/ .el-tab-pane {
      // background: #848484;
      p {
        color: rgba(132, 132, 132, 1);
      }
    }

    /deep/.el-tabs__nav-wrap::after {
      background-color: rgba(255, 255, 255, 0);
    }
    /deep/.el-tabs__active-bar {
      background-color: rgba(255, 255, 255, 0);
    }
    img {
      width: 100%;
    }
    h1 {
      font-size: 1.2rem;
      line-height: 4rem;
      // font-weight: 550;
    }
    /deep/p {
      // background: #0b4696;
      font {
        line-height: 2rem;
      }
    }
    p {
      line-height: 2rem;
      color: rgba(132, 132, 132, 1);
      overflow: hidden;
      background: #0b4696;
      // .image {
      //   width: 300px;
      //   float: left;
      //   margin-top: 30px;
      //   margin-right: 10px;
      // }
      span {
        margin-top: 20px;
        display: block;

        h3 {
          line-height: 30px;
          font-size: 14px;
          font-weight: 550;
        }
        p {
          font-size: 14px;
          line-height: 25px;
        }
      }
    }
  }
}
</style>
