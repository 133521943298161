<template>
  <div class="main">
    <div class="text">
      <h1>进—步探索区块链工作会议</h1>
      <span>2020-10-10 宁明编辑部</span>
      <div>
        <p style="font-weight: 550">区块链是什么</p>
        <p>
          许多人对区块链的理解还停留在雾里看花的状态，甚至有不少人认为区块链就是比特币。
        </p>
        <p>
          事实上，区块链技术是一种通过去中心化去信任的方式来集体维护一个可靠数据库的方案。所有系统的背后都有一个数据库，如果把数据库看成一个账本，传统的记账方式只有唯一的系统所有者可以记账，而区块链技术让系统中的每一个人都可以有机会竞争参与记账。在一定时间段内如果有任何数据变化，系统中每个人都进行记账，系统会评判这段时间内记账最快最好的人，把他记录的内容写到账本，并将这段时间内的账本内容发给系统内的所有人进行备份。这样系统中的每个人都了一本完整的账本。
        </p>
        <p>
          区块链随着比特币而诞生，作为比特币的底层技术，它具有无需中介参与、过程高效透明且成本低、数据高度安全等优势，为比特币去中心化的分散式系统提供了支持。比特币可以看作是区块链的第一个应用，也是目前区块链技术工业化最成功最成熟的例子，但是区块链技术的应用却不会止步于比特币。
        </p>
        <p style="font-weight: 550">区块链技术应用的探索</p>
        <p>
          2015年12月，区块链研究联盟和区块链应用研究中心的成立为我国区块链技术的发展建立了第一个里程碑。2016年以来，国内先后成立了多个区块链联盟组织，研究和发展区块链技术成为热潮。此外，国内区块链政策也趋于明晰化，2016年10月工信部发布的《中国区块链技术和应用发展白皮书》、2016年12月国务院发布的《十三五国家信息规划》以及2017年5月16日工信部发布的《区块链参考架构》，都表明了监管机构对其持开放扶持态度。
        </p>
        <p>
          业界普遍将区块链技术的应用划分为三个发展层次：区块链1.0是以比特币为代表的数字货币应用；区块链2.0是数字货币和智能合约的结合在其他金融领域的运用；区块链3.0则进一步将区块链应用领域扩展到金融行业之外的社会生活中，包括但不限于政府、医疗、司法、物流等领域。在金融领域，区块链技术具有很大的潜力。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.main {
  .text {
    width: 85%;
    margin: 2.5rem auto;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 2.5rem;
    }
    span {
      display: block;
      margin-bottom: 2rem;
      color: rgba(144, 144, 153, 1);
      font-size: 0.8rem;
      // background: chartreuse;
      text-align: center;
    }
    div {
      margin-bottom: 1rem;
      h3 {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.788);
      }
      p {
        font-size: 0.9rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.664);
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
          "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        margin-bottom: 1rem;
      }
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }
}
</style>
