<template>
  <div class="text">
    <h1>软件开发服务</h1>
    <div>
      <p>
        2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。这部法律也成为我国针对CA及数字证书的合法与确定性立法。2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。这部法律也成为我国针对CA
        及数字证书的合法与确定性立法。
      </p>
    </div>
    <div>
      <p>
        2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。这部法律也成为我国针对CA及数字证书的合法与确定性立法。2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。这部法律也成为我国针对CA
        及数字证书的合法与确定性立法。
      </p>
    </div>
    <div>
      <img
        src="../../assets/img/mobile2-3.png"
        alt=""
      />
    </div>
    <div>
      <p>
        2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。这部法律也成为我国针对CA及数字证书的合法与确定性立法。2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。这部法律也成为我国针对CA
        及数字证书的合法与确定性立法。
      </p>
    </div>
    <div>
      <p>
        2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。这部法律也成为我国针对CA及数字证书的合法与确定性立法。2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。这部法律也成为我国针对CA
        及数字证书的合法与确定性立法。
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.main {
  .text {
    width: 85%;
    margin: 1rem auto;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 5rem;
    }
    div {
      margin-bottom: 1rem;
      h3 {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.788);
      }
      p {
        font-size: 0.9rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.664);
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
          "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
      }
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }
}
</style>
