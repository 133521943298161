import axios from "axios";

// create an axios instance
const service = axios.create({
  // baseURL: 'http://192.168.10.156:88/', // url = base url + request url
  baseURL: "", // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  //headers: {"Content-Type":"application/x-www-form-urlencoded;charset=UTF-8","Access-Control-Allow-Headers":"Origin,X-Requested-With,Content-Type,Accept,Authorization","Access-Control-Allow-Methods":"GET,POST,PUT,DELETE,OPTIONS,PATCH" },
  timeout: 5000, // request timeout
});
axios.defaults.withCredentials = true;
// request interceptor
service.interceptors.request.use((config) => config);

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  (response) => {
    return response.data;
  }
);

export default service;
