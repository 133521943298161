import { render, staticRenderFns } from "./page8.vue?vue&type=template&id=5eb0bd60&scoped=true&"
import script from "./page8.vue?vue&type=script&lang=js&"
export * from "./page8.vue?vue&type=script&lang=js&"
import style0 from "./page8.vue?vue&type=style&index=0&id=5eb0bd60&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.10.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5eb0bd60",
  null
  
)

export default component.exports