<template>
  <!-- pc端的 -->
  <div
    class="main main-box"
    v-if="drawershow == 'true'"
  >
    <ul>
      <li
        v-for="(item, index) in listObj3"
        :key="index"
      >
        <router-link :to="{ path: '/home/home6/page2', query: { id: item.id } }">
          <img
            :src="item.tiltePic"
            alt=""
          />
          <div class="min-logo">
            <p>{{ item.createTime }}</p>
          </div>
          <div class="text">
            <p>{{ item.title }}</p>
            <p></p>
            <div
              v-html="item.info"
              class="text-content"
            ></div>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
  <!-- 手机端 -->
  <div
    class="main-mobile"
    v-else
  >
    <div class="title">
      <div class="img"></div>
    </div>
    <div class="content">
      <div class="content1">
        <ul>
          <li
            v-for="(item, index) in listObj3"
            :key="index"
          >
            <router-link
              :to="{ path: '/home/home6/page2', query: { id: item.id } }"
              class="routerlink"
            >
              <div class="img">
                <img
                  :src="item.tiltePic"
                  alt=""
                />
              </div>
              <div class="text">
                <h3>{{ item.title }}</h3>
                <p>{{ item.info }}</p>
                <p class="p1">{{ item.createTime }}</p>
              </div>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/policy";

export default {
  data () {
    return {
      listObj3: [
        // {
        //   img: require("../../assets/img/新闻1@2x.png"),
        //   time: "2021年9月27日",
        //   title: "国庆节放假通知",
        //   text: "服务源于使命创新引领未来服务源于使命创新引领未来服务源于使命创新引领未来源于使命创新引领未来源于使命创新引领未",
        // },
        // {
        //   img: require("../../assets/img/新闻2@2x.png"),
        //   time: "2021年9月25日",
        //   title: "进一步探索区块链工作会议",
        //   text: "服务源于使命创新引领未来服务源于使命创新引领未来服务源于使命创新引领未来源于使命创新引领未来源于使命创新引领未",
        // },
        // {
        //   img: require("../../assets/img/新闻3@2x.png"),
        //   time: "2021年9月20日",
        //   title: "电子签章应用场景讨论会",
        //   text: "服务源于使命创新引领未来服务源于使命创新引领未来服务源于使命创新引领未来源于使命创新引领未来源于使命创新引领未",
        // },
        // {
        //   img: require("../../assets/img/新闻4@2x.png"),
        //   time: "2021年9月21日",
        //   title: "中秋节放假通知",
        //   text: "服务源于使命创新引领未来服务源于使命创新引领未来服务源于使命创新引领未来源于使命创新引领未来源于使命创新引领未",
        // },
      ],
      pagingList: [
        {
          id: 1,
          img: require("../../assets/img/news1-1.png"),
          title: "国庆节放假通知",
          textContent:
            " 上海宁明2021年国庆假日:根据国家法定假期的规定,并结合公司实际情况,现对中秋假期做如下安排...",
          ReleaseTime: "2021年9月27日",
          pathAddress: "/home/home6/page2/HomePage8",
        },
        {
          id: 2,
          img: require("../../assets/img/news1-2.png"),
          title: "进一步探索区块链工作会议",
          textContent:
            " 许多人对区块链的理解还停留在雾里看花的状态，甚至有不少人认为区块链就是比特币。事实上，区块链技术是—种通过...",
          ReleaseTime: "2021年9月25日",
          pathAddress: "/home/home6/page2/HomePage9",
        },
        {
          id: 3,
          img: require("../../assets/img/news1-3.png"),
          title: "电子签章应用场景讨论会",
          textContent:
            " 9月22日下午公司组织开展了关于数字证书概念的讨论会。数字证书是一个经证书授权中心数字签名的包含公开密钥拥有者信息...",
          ReleaseTime: "2021年9月20日",
          pathAddress: "/home/home6/page2/HomePage10",
        },
      ],
      drawershow: sessionStorage.getItem("drawershow"),
    };
  },
  created () {
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });

    var that = this;
    var data = { category2: "24" };
    getList(data).then((res) => {
      that.listObj3 = res.data.records;
      console.log(that.listObj3, "that.listObj3");
      that.listObj3.forEach((item) => {
        const arr = item.createTime.split(/[-: ]/);
        const arr1 = arr[0] + "年" + arr[1] + "月" + arr[2] + "日";
        this.$set(item, "createTime", arr1);
      });
    });
  },
};
</script>

<style lang="less" scoped>
.main {
  // width: 100%;
  height: auto;
  background: #fff;
  padding-top: 4rem;
  padding-bottom: 5rem;
  // border: 1px solid red;
  ul {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    li {
      width: 31.5%;
      height: auto;
      // background: lemonchiffon;
      border: 1px solid #f7f7f7;
      border-top: none;
      position: relative;
      margin-bottom: 1.5rem;
      transition: All 0.4s ease-in-out;
      margin: 0.5rem 0.55rem;
      a {
        text-decoration: none;
      }
      img {
        width: 100%;
        height: 12.75rem;
      }
      .min-logo {
        width: 6.5rem;
        height: 2rem;
        background: #104a98;
        position: absolute;
        top: 12rem;
        left: 1.5rem;
        p {
          color: #fff;
          text-align: center;
          line-height: 2rem;
          font-size: 0.7rem;
          letter-spacing: 1px;
        }
      }
      .text {
        width: 100%;
        height: auto;
        // background: lightblue;
        padding: 1.5rem;
        p:nth-child(1) {
          font-size: 1.1rem;
          font-weight: 550;
          letter-spacing: 0.1rem;
          margin-bottom: 1rem;
          margin-top: 1rem;
          color: #222;
        }
        p:nth-child(2) {
          width: 3rem;
          height: 0.15rem;
          background: #222;
          margin-bottom: 1rem;
        }
        .text-content {
          font-size: 0.65rem;
          letter-spacing: 1px;
          margin-bottom: 0.2rem;
          line-height: 1.25rem;
          text-align: justify;
          color: #848484;
        }
      }
    }
    li:hover {
      // border: 1px solid rgba(0, 0, 0, 0.212);
      color: #104a98;
      transform: translate(0, -0.5rem);
      box-shadow: 0rem 0.25rem 0.75rem #e5e5e5;
    }
    li:hover .text {
      p:nth-child(1) {
        color: #104a98;
      }
      p:nth-child(2) {
        background: #104a98;
      }
    }
  }
}
.main-mobile {
  .title {
    width: 100%;
    height: 45vw;
    // background: blanchedalmond;
    .img {
      width: 100%;
      height: 45vw;
      // border: 1px solid black;
      background: url("../../assets/img/mobile7-1.png") no-repeat;
      background-size: 100% 100%;
    }
  }
  .content {
    width: 100%;
    height: auto;
    background: #fff;
    padding: 1rem;
    // margin-bottom: 5rem;

    .content1 {
      width: 100%;
      height: auto;
      // background: rgb(163, 162, 161);
      margin-bottom: 1rem;
      ul {
        li {
          .routerlink {
            width: 100%;
            height: 7rem;
            border-bottom: 1px solid rgb(238, 238, 238);
            display: flex;
            align-items: center;
            justify-content: space-between;
            .img {
              width: 38%;
              height: 77%;
              // border: 1px solid red;
              // background: url("../../assets/img/组 22.png") no-repeat;
              // background-size: 100% 100%;
              float: left;
              border-radius: 0.4rem;
              display: flex;
              justify-content: center;
              // border: 1px solid black;
              img {
                // width: 100%;
                height: 100%;
              }
            }
          }

          .text {
            width: 60%;
            height: auto;
            // background: burlywood;
            float: left;
            h3 {
              font-size: 0.9rem;
              margin-bottom: 0.5rem;
            }
            p {
              font-size: 0.8rem;
              line-height: 1.4rem;
              color: rgba(96, 96, 102, 1);
              overflow: hidden;
              // white-space: nowrap;
              text-overflow: ellipsis;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              -webkit-line-clamp: 2; /* 设置超过为省略号的行数 */
              width: 100%;
            }
            .p1 {
              font-size: 0.6rem;
              color: rgba(144, 144, 153, 1);
            }
          }
        }
        li:hover {
          background: rgba(210, 210, 247, 0.15);
        }
      }
    }
    .paging {
      width: 85%;
      height: 4rem;
      // background: rgb(66, 66, 66);
      padding-top: 1rem;
      margin: auto;
      // display: flex;
      .van-pagination {
        // width: 60%;
        margin: auto;
        font-size: 1rem;
        border: none;
        /deep/.van-pagination__item {
          border-radius: 0.2rem;
          color: rgba(204, 204, 204, 1);
          border: #fff;
          height: 2rem;
          min-width: 2rem;
        }
      }
      /deep/.van-pagination__item:first-child {
        // margin-right: 1rem;
        // background: url("../../assets/img/组 23.png") no-repeat;
        background-size: 20%;
        background-position: 50%;
      }
      /deep/.van-pagination__item:last-child {
        // margin-left: 1rem;
        // background: url("../../assets/img/组 24.png") no-repeat;
        background-size: 20%;
        background-position: 50%;
      }
    }
  }
}
</style>
