<template>
  <div class="main">
    <div class="text">
      <h1>电子签章应用场景讨论会</h1>
      <span>2020-10-10 宁明编辑部</span>
      <div>
        <p style="font-weight: 550">
          9月22日下午公司组织开展了关于数字证书概念的讨论会。
        </p>
        <p style="font-weight: 550">关于数字证书的概念</p>
        <p>
          从技术部门同事角度来说，数字证书是一个经证书授权中心数字签名的包含公开密钥拥有者信息以及公开密钥的文件。最简单的证书包含一个公开密钥、名称以及证书授权中心的数字签名。数字证书还有一个重要特征就是只在特定的时间段内有效。从产品部门同事角度来说，数字证书就是互联网通讯中标志通讯各方身份信息的一串数字，提供了一种在Internet上验证通信实体身份的方式，数字证书是身份认证机构盖在数字身份证上的一个章或印（或者说是加在数字身份证上的一个签名）。它是由权威机构——CA机构，又称为证书授权（Certificate
          Authority）中心发行的，人们可以在网上用它来识别对方的身份。从业务部门同事角度来说，数字证书就是在网络里面标识个人有效身份的身份证。
        </p>
        <p style="font-weight: 550">关于电子签名法保障CA的法律效力</p>
        <p>
          2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。这部法律也成为我国针对CA及数字证书的合法与确定性立法。2005年4月1日，中华人民共和国第十届全国人民代表大会常务委员会第十一次会议通过并颁布《中华人民共和国电子签名法》，成为我国首部涉及互联网应用的法律条文。
          这部法律也成为我国针对CA及数字证书的合法与确定性立法
        </p>
        <p style="font-weight: 550">如何保证信息安全</p>
        <p>保密性（Confidentiality）</p>
        <p>
          即保证信息为授权者享用而不泄漏给未经授权者。对纸质文档信息，我们只需要保护好文件，不被非授权者接触即可。而对计算机及网络环境中的信息，不仅要制止非授权者对信息的阅读。也要阻止授权者将其访问的信息传递给非授权者，以致信息被泄漏。
        </p>
        <p>完整性（Integrity）</p>
        <p>
          防止信息被未经授权的篡改。它是保护信息保持原始的状态，使信息保持其真实性。如果这些信息被蓄意地修改、插入、删除等，形成虚假信息将带来严重的后果。
        </p>
        <p>可用性（Availability）</p>
        <p>
          授权主体在需要信息时能及时得到服务的能力。可用性是在信息安全保护阶段对信息安全提出的新要求，也是在网络化空间中必须满足的一项信息安全要求。
        </p>
        <p>可控性(Controlability)</p>
        <p>对信息和信息系统实施安全监控管理，防止非法利用信息和信息系统</p>
        <p>不可否认性(Non-repudiation)</p>
        <p>
          指在网络环境中，信息交换的双方不能否认其在交换过程中发送信息或接收信息的行为。
        </p>
        <p>
          随着国内电子签名大众化元年的到来，我们在受益于其带来便捷、高效、安全的同时，存证举证和司法服务的完整性也成为了用户最核心的关注点。毕竟签名是为了保障权益，让签名成为证据才具有意义和价值。因此，有效的电子签名构建的不仅仅是一个签署模式革新，更因为其符合《中华人民共和国电子签名法》规定中“真实身份、真实意愿、原文未改、签名未改”四大要素，使其成为了安全有效的电子证据，从而打造出新兴的法律服务模式。
        </p>
        <p style="font-weight: 550">小常识：</p>
        <p>
          由权威机构——CA机构，又称为证书授权（Certificate
          Authority）中心发行的，人们可以在网上用它来识别对方的身份。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.main {
  .text {
    width: 85%;
    margin: 2.5rem auto;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 2.5rem;
    }
    span {
      display: block;
      margin-bottom: 2rem;
      color: rgba(144, 144, 153, 1);
      font-size: 0.8rem;
      // background: chartreuse;
      text-align: center;
    }
    div {
      margin-bottom: 1rem;
      h3 {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.788);
      }
      p {
        font-size: 0.9rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.664);
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
          "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        margin-bottom: 1rem;
      }
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }
}
</style>
