<template>
  <div>
    <Main :name="name" />
  </div>
</template>

<script>
import Main from "../components/Home7/Main.vue";
export default {
  data () {
    return {
      name: "7",
    };
  },
  components: {
    Main,
  },
  mounted () {
    console.log(this.$route.name);
    this.name = this.$route.name;
  },
};
</script>

<style></style>
