<template>
  <div class="main">
    <!-- <p>{{ drawershow }}11111</p> -->
    <!-- pc端 -->
    <div
      class="main-pc"
      v-if="drawershow == 'true'"
    >
      <div class="mian1 main-box">
        <div class="top">
          <p class="p1">{{ main1.title }}</p>
          <div class="line">
            <div class="min-line"></div>
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
            <div class="min-line"></div>
          </div>
          <div
            class="p2"
            v-html="main1.titleText"
          ></div>
        </div>
        <div class="bottom">
          <ul>
            <li
              v-for="(item, index) in listObj1"
              :key="index"
              @click="tohome2(item)"
            >
              <!-- <router-link to="/home/home2"> -->
              <img
                :src="item.tiltePic"
                alt=""
              />
              <div class="min-logo">
                <img
                  :src="pics[index].img"
                  alt=""
                />
              </div>
              <div class="text">
                <p>{{ item.title }}</p>
                <p>
                  {{ item.info }}
                </p>
              </div>
              <!-- </router-link> -->
            </li>
          </ul>
        </div>
      </div>
      <div class="main2 main-box">
        <div class="main2-content">
          <div class="top">
            <p class="p1">{{ main2.title }}</p>
            <div class="line">
              <div class="min-line"></div>
              <div class="round"></div>
              <div class="round"></div>
              <div class="round"></div>
              <div class="min-line"></div>
            </div>
            <div
              class="p2"
              v-html="main2.titleText"
            ></div>
          </div>
          <div class="bottom">
            <ul>
              <li
                v-for="(item, index) in listObj2"
                :key="index"
                @click="tohome3(item)"
              >
                <div class="content">
                  <img
                    :src="item.tiltePic"
                    alt=""
                  />
                  <p class="p1">{{ item.title }}</p>
                  <div
                    class="p2"
                    v-html="item.info"
                  ></div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="mian3 main-box">
        <div class="top">
          <p class="p1">{{ main3.title }}</p>
          <div class="line">
            <div class="min-line"></div>
            <div class="round"></div>
            <div class="round"></div>
            <div class="round"></div>
            <div class="min-line"></div>
          </div>
          <div
            class="p2"
            v-html="main3.titleText"
          ></div>
        </div>
        <div class="bottom">
          <ul>
            <li
              v-for="(item, index) in listObj3"
              :key="index"
              @click="tohome6"
            >
              <router-link :to="{ path: '/home/home6/page2', query: { id: item.id } }">
                <img
                  :src="item.tiltePic"
                  alt=""
                />
                <div class="min-logo">
                  <p>{{ item.createTime }}</p>
                </div>
                <div class="text">
                  <p>{{ item.title }}</p>
                  <p></p>
                  <div
                    v-html="item.info"
                    class="text-content"
                  ></div>
                </div>
              </router-link>
            </li>
          </ul>
        </div>
      </div>
      <div class="mian4 main-box">
        <div class="main4-content">
          <p>{{ main4.title }}</p>
          <p>
            {{ main4.titleText }}
          </p>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div
      class="main-mobile"
      v-else
    >
      <div class="main">
        <div class="main1">
          <div class="top">
            <h1>产品创新</h1>
            <div class="line">
              <div class="min-line"></div>
              <div class="circular"></div>
              <div class="circular"></div>
              <div class="circular"></div>
              <div class="min-line"></div>
            </div>
            <p>
              产品创新是企业技术创新中最重要、最基本的内容，是企业技术创新的核心宁明软件根据市场的需要和需求的变化坚持进行研究开发，不断地推出能够满足用户要求的新产品
            </p>
          </div>
          <div class="bottom">
            <van-swipe
              class="my-swipe"
              :autoplay="3000"
              indicator-color="white"
            >
              <van-swipe-item
                v-for="(item, index) in listObj1"
                :key="index"
                @click="tohome2(item)"
              >
                <div class="box">
                  <div class="img img1">
                    <img
                      :src="item.tiltePic"
                      alt=""
                    />
                    <img
                      :src="pics[index].img"
                      alt=""
                      class="min-logo"
                    />
                    <!-- <div class="min-logo"></div> -->
                  </div>
                  <div class="text">
                    <h3>{{ item.title }}</h3>
                    <p>
                      {{ item.info }}
                    </p>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
        <div class="main2">
          <div class="top">
            <h1>服务支持</h1>
            <div class="line">
              <div class="min-line"></div>
              <div class="circular"></div>
              <div class="circular"></div>
              <div class="circular"></div>
              <div class="min-line"></div>
            </div>
            <p>
              宁明软件始终将客户服务作为工作的重点，公司业务覆盖全国大多数省市区域拥有专业的技术服务团队，全心全意为客户提供过硬、优质的技术保障
            </p>
          </div>
          <div class="bottom">
            <ul>
              <router-link
                tag="li"
                :to="'/Home/Home3' + '?title=' + item.category2"
                v-for="(item, index) in listObj2"
                :key="index"
              >
                <div class="img img1">
                  <img
                    :src="item.tiltePic"
                    alt=""
                  />
                </div>
                <div class="text">
                  <span>{{ item.title }}</span>
                  <p>{{ item.info }}</p>
                </div>
              </router-link>
            </ul>
          </div>
        </div>
        <div class="main3">
          <div class="top">
            <h1>新闻中心</h1>
            <div class="line">
              <div class="min-line"></div>
              <div class="circular"></div>
              <div class="circular"></div>
              <div class="circular"></div>
              <div class="min-line"></div>
            </div>
            <p>
              每一篇报道都是宁明软件各团队的一小步，却是公司日渐强大的一大步唯独不变的是我们的初心——创新和服务
            </p>
          </div>
          <div class="bottom">
            <van-swipe
              class="my-swipe"
              :autoplay="3000"
              indicator-color="white"
            >
              <router-link
                v-for="(item, index) in listObj3"
                :key="index"
                tag="van-swipe-item"
                :to="{ path: '/home/home6/page2', query: { id: item.id } }"
              >
                <div class="box">
                  <div class="img img1">
                    <img
                      :src="item.tiltePic"
                      alt=""
                    />
                    <div class="min-logo">{{ item.createTime }}</div>
                  </div>
                  <div class="text">
                    <h3>{{ item.title }}</h3>
                    <div class="line"></div>
                    <p v-html="item.info"></p>
                  </div>
                </div>
              </router-link>
              <!-- <router-link
                tag="van-swipe-item"
                to="/home/home6/page2/HomePage9"
              >
                <div class="box">
                  <div class="img img2">
                    <div class="min-logo">2021年9月25日</div>
                  </div>
                  <div class="text">
                    <h3>进一步探索区块链工作会议</h3>
                    <div class="line"></div>
                    <p>
                      许多人对区块链的理解还停留在雾里看花的状态，甚至有不少人认为区块链就是比特币。事实上，区块链技术是—种通过...
                    </p>
                  </div>
                </div>
              </router-link>

              <router-link
                tag="van-swipe-item"
                to="/home/home6/page2/HomePage10"
              >
                <div class="box">
                  <div class="img img3">
                    <div class="min-logo">2021年9月20日</div>
                  </div>
                  <div class="text">
                    <h3>电子签章应用场景讨论会</h3>
                    <div class="line"></div>
                    <p>
                      9月22日下午公司组织开展了关于数字证书概念的讨论会。数字证书是一个经证书授权中心数字签名的包含公开密钥拥有者信息...
                    </p>
                  </div>
                </div>
              </router-link> -->
            </van-swipe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/policy";

export default {
  // props: ["drawershow"],
  data () {
    return {
      // name: "",
      main1: {
        title: "产品创新",
        titleText:
          "产品创新是企业技术创新中最重要、最基本的内容，是企业技术创新的核心<br />宁明软件根据市场的需要和需求的变化坚持进行研究开发，不断地推出能够满足用户要求的新产品",
      },
      pics: [
        { img: require("../../assets/img/11.png") },
        { img: require("../../assets/img/22.png") },
        { img: require("../../assets/img/33.png") },
      ],
      listObj1: [],
      main2: {
        title: "服务支持",
        titleText:
          "宁明软件始终将客户服务作为工作的重点，公司业务覆盖全国大多数省市区域<br />拥有专业的技术服务团队，全心全意为客户提供过硬、优质的技术保障",
      },
      listObj2: [],
      main3: {
        title: "新闻中心",
        titleText:
          " 每一篇报道都是宁明软件各团队的一小步，却是公司日渐强大的一大步<br />唯独不变的是我们的初心——创新和服务",
      },
      listObj3: [],
      main4: {
        title: "致电400-105-1009获取您的专属解决方案",
        titleText:
          " 丰富的行业纵深，无论您的企业处在什么样的发展阶段，都可以获得相应的方案设计",
      },
      drawershow: sessionStorage.getItem("drawershow"), //是否打开pc端样式
    };
  },
  methods: {
    tohome2 (item) {
      document.documentElement.scrollTop = 0;
      sessionStorage.setItem("home2", item.category2);
      this.$router.push("/home/home2/" + "?title=" + item.category2);
    },
    tohome3 (item) {
      document.documentElement.scrollTop = 0;
      console.log(item, "item");
      this.$router.push("/home/home3" + "?title=" + item.category2);
      sessionStorage.setItem("home3", item.category2);
    },
    tohome6 () {
      document.documentElement.scrollTop = 0;
    },
  },
  created () {
    // console.log(sessionStorage.getItem("drawershow"), 11111);
    // this.drawershow = sessionStorage.getItem("drawershow")

    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });
    var that = this;
    var data = { category2: "24" };
    getList(data).then((res) => {
      that.listObj3 = res.data.records.slice(0, 3);
      console.log(that.listObj3, "that.listObj3");
      that.listObj3.forEach((item) => {
        const arr = item.createTime.split(/[-: ]/);
        const arr1 = arr[0] + "年" + arr[1] + "月" + arr[2] + "日";
        this.$set(item, "createTime", arr1);
      });
    });

    var data1 = { category: "4" };
    getList(data1).then((res) => {
      that.listObj1 = res.data.records;
      console.log(that.listObj1, "that.listObj1");
    });
    var data2 = { category: "5" };
    getList(data2).then((res) => {
      that.listObj2 = res.data.records;

      that.listObj2.forEach((item) => {
        this.$set(item, "info", item.info.replace("<br/>", ""));
        console.log(item.info.replace("<br/>", ""), 99999);
      });
    });
  },
};
</script>

<style lang="less" scoped>
.main-pc {
  width: 100%;
  height: auto;
  background: #fff;
  .main-box {
    width: 60.2rem;
    margin: auto;
  }
  .mian1 {
    height: auto;
    background: #fff;
    padding-top: 5rem;
    padding-bottom: 5rem;
    .top {
      width: 100%;
      height: 7.5rem;
      // background: hotpink;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .p1 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        letter-spacing: 0.2rem;
      }
      .p2 {
        width: auto;
        text-align: center;
        line-height: 1.5rem;
        font-size: 0.7rem;
        color: rgba(132, 132, 132, 1);
      }
      .line {
        width: 25rem;
        height: 1.25rem;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
        div {
          margin: 0.25rem;
        }
        .min-line {
          width: 4rem;
          height: 1px;
          background: rgba(11, 70, 150, 1);
        }
        .round {
          width: 0.3rem;
          height: 0.3rem;
          background: rgba(11, 70, 150, 1);
          border-radius: 50%;
        }
      }
    }
    .bottom {
      width: 100%;
      height: 22.5rem;
      // background: rgb(243, 185, 214);
      ul {
        display: flex;
        justify-content: space-between;
        li {
          width: 31.5%;
          height: 22.5rem;
          // background: lemonchiffon;
          border: 1px solid #f7f7f7;
          border-top: none;
          transition: All 0.4s ease-in-out;
          position: relative;
          a {
            text-decoration: none;
            color: #222;
          }
          img {
            width: 100%;
            height: 12.75rem;
          }
          .min-logo {
            width: 3.5rem;
            height: 3.5rem;
            background: rgba(11, 70, 150, 1);
            border-radius: 0.25rem;
            position: absolute;
            top: 11rem;
            right: 1.5rem;
            display: flex;
            img {
              width: 3.25rem;
              height: 3.25rem;
              margin: auto;
            }
          }
          .text {
            width: 100%;
            height: 9.5rem;
            // background: lightblue;
            padding: 1.5rem;
            p:nth-child(1) {
              font-size: 1.1rem;
              font-weight: 550;
              letter-spacing: 0.2rem;
              margin-bottom: 0.65rem;
            }
            p:nth-child(2) {
              font-size: 0.65rem;
              // letter-spacing: 0.1vw;
              margin-bottom: 0.2rem;
              line-height: 1.5rem;
              text-align: justify;
              color: rgba(132, 132, 132, 1);
            }
          }
        }
        li:hover {
          // border: 1px solid rgba(0, 0, 0, 0.212);
          transform: translate(0, -0.5rem);
          box-shadow: 0rem 0.25rem 0.75rem #e5e5e5;
        }
        li:hover a {
          color: rgba(11, 70, 150, 1);
        }
      }
    }
  }
  .main2 {
    width: 100%;
    height: auto;
    background: #f8f8f8;
    .main2-content {
      width: 60.2rem;
      height: 100%;
      padding-top: 5rem;
      padding-bottom: 5rem;
      margin: auto;
      background: #f8f8f8;
      .top {
        width: 100%;
        height: 7.5rem;
        // background: hotpink;
        margin-bottom: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        .p1 {
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
          // font-weight: 550;
          letter-spacing: 4px;
        }
        .p2 {
          width: auto;
          // height: 350px;
          // background: gray;
          text-align: center;
          line-height: 1.5rem;
          font-size: 0.7rem;
          color: rgba(132, 132, 132, 1);
        }
        .line {
          width: 25rem;
          height: 1.25rem;
          // background: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0.5rem;
          div {
            margin: 0.25rem;
          }
          .min-line {
            width: 4rem;
            height: 1px;
            background: rgba(11, 70, 150, 1);
          }
          .round {
            width: 0.3rem;
            height: 0.3rem;
            background: rgba(11, 70, 150, 1);
            border-radius: 50%;
          }
        }
      }
      .bottom {
        width: 100%;
        height: 12.5rem;
        // background: orange;
        // margin-top: 2vw;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: space-between;
          li {
            box-sizing: border-box;
            padding: 20px;
            width: 14rem;
            height: 100%;
            background: #fff;
            display: flex;
            justify-content: center;
            transition: All 0.4s ease-in-out;
            .content {
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              text-decoration: none;
              color: #222;
              img {
                width: 3rem;
                height: 3rem;
              }
              .p1 {
                font-size: 1rem;
                font-weight: 600;
                // height: 2vw;
                line-height: 2rem;
                margin-top: 0.75rem;
              }
              .p2 {
                text-align: center;
                line-height: 1.25rem;
                letter-spacing: 0.1rem;
                font-size: 0.6rem;
                color: #848484;
              }
            }
          }
          li:hover {
            // border: 1px solid rgba(0, 0, 0, 0.062);
            transform: translate(0, -0.5rem);
            box-shadow: 0px 0.25rem 0.75rem #e5e5e5;
          }
          li:hover a {
            color: rgba(11, 70, 150, 1);
          }
        }
      }
    }
  }
  .mian3 {
    height: auto;
    background: #fff;
    padding-top: 5rem;
    padding-bottom: 5rem;
    .top {
      width: 100%;
      height: 7.5rem;
      // background: hotpink;
      margin-bottom: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      .p1 {
        font-size: 1.5rem;
        margin-bottom: 0.5rem;
        // font-weight: 550;
        letter-spacing: 0.2rem;
      }
      .p2 {
        width: auto;
        // height: 350px;
        // background: gray;
        text-align: center;
        line-height: 1.5rem;
        font-size: 0.7rem;
        color: rgba(132, 132, 132, 1);
      }
      .line {
        width: 25rem;
        height: 1.25rem;
        // background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 0.5rem;
        div {
          margin: 0.25rem;
        }
        .min-line {
          width: 4rem;
          height: 1px;
          background: rgba(11, 70, 150, 1);
        }
        .round {
          width: 0.3rem;
          height: 0.3rem;
          background: rgba(11, 70, 150, 1);
          border-radius: 50%;
        }
      }
    }
    .bottom {
      width: 100%;
      height: auto;
      // background: rgb(243, 185, 214);
      ul {
        display: flex;
        justify-content: space-between;
        li {
          width: 31.5%;
          height: auto;
          // background: lemonchiffon;
          border: 1px solid #f7f7f7;
          border-top: none;
          position: relative;
          transition: all 0.4s ease-in-out;

          a {
            text-decoration: none;
            color: #222;
          }
          img {
            width: 100%;
            height: 12.75rem;
          }
          .min-logo {
            width: 6.5rem;
            height: 2rem;
            background: rgba(11, 70, 150, 1);
            position: absolute;
            top: 12rem;
            left: 1.5rem;
            p {
              color: #fff;
              text-align: center;
              line-height: 2rem;
              font-size: 0.7rem;
              letter-spacing: 1px;
            }
          }
          .text {
            width: 100%;
            height: auto;
            // background: lightblue;
            padding: 1.5rem;
            p:nth-child(1) {
              font-size: 1.1rem;
              font-weight: 550;
              letter-spacing: 0.1rem;
              margin-bottom: 1rem;
              margin-top: 1rem;
            }
            p:nth-child(2) {
              width: 3rem;
              height: 0.15rem;
              background: #222;
              margin-bottom: 1rem;
            }
            .text-content {
              font-size: 0.65rem;
              letter-spacing: 1px;
              margin-bottom: 0.2rem;
              line-height: 1.25rem;
              text-align: justify;
              color: #848484;
            }
          }
        }
        li:hover {
          // border: 1px solid rgba(0, 0, 0, 0.212);
          transform: translate(0, -0.5rem);
          box-shadow: 0rem 0.25rem 0.75rem #e5e5e5;
        }
        li:hover a {
          color: rgba(11, 70, 150, 1);
        }
        li:hover .text {
          p:nth-child(2) {
            background: rgba(11, 70, 150, 1);
          }
        }
      }
    }
  }
  .mian4 {
    height: 11rem;
    width: 100%;
    background: #f8f8f8;
    .main4-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      p:nth-child(1) {
        font-size: 1.5rem;
        // font-weight: 600;
        // line-height: 4vw;
      }
      p:nth-child(2) {
        font-size: 0.8rem;
        letter-spacing: 0.2rem;
        margin-top: 1rem;
        color: #848484;
      }
    }
  }
}
.main-mobile {
  .main {
    width: 100%;
    height: auto;
    background: #fff;
    padding: 2rem 0.2rem 0rem 0.2rem;
    .main1 {
      width: 100%;
      height: auto;
      // background: rgb(201, 39, 39);
      .top {
        // background: chartreuse;
        h1 {
          font-size: 1.5rem;
          text-align: center;
        }
        .line {
          width: 14rem;
          height: 2rem;
          // background: chartreuse;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin: auto;
          div {
            overflow: hidden;
            float: left;
            margin: 0em 0.1em 0em 0.1em;
            // padding: 0.1rem;
          }
          .min-line {
            height: 0.05rem;
            width: 3.5rem;
            background: rgba(11, 70, 150, 1);
          }
          .circular {
            width: 0.5rem;
            height: 0.5rem;
            clip-path: circle(50%);
            background: rgba(11, 70, 150, 1);
            // border-radius: 50%;
          }
        }
        p {
          width: 76%;
          margin: auto;
          text-align: center;
          line-height: 1.5rem;
          color: rgba(144, 144, 153, 1);
          font-size: 0.7rem;
        }
      }
      .bottom {
        width: 100%;
        height: auto;
        overflow: hidden;
        .my-swipe .van-swipe-item {
          height: 28rem;
          color: #fff;
          font-size: 1.25rem;
          line-height: 9.375rem;
          text-align: center;
          background-color: ;
          padding: 1rem;
          margin-top: 1rem;
          float: left;
          .box {
            width: 100%;
            height: 24rem;
            border-radius: 0.6rem;
            background: #fff;
            box-shadow: 0px 0px 13px rgb(218, 217, 217);

            .img {
              width: 100%;
              height: 60%;
              position: relative;
              // border: 1px solid brown;
              img {
                width: 100%;
                height: 14.6rem;
              }
              .min-logo {
                width: 3.6rem;
                height: 3.6rem;
                background: #0b4696;
                position: absolute;
                bottom: -2rem;
                right: 1.5rem;
                border-radius: 0.5rem;
              }
            }

            .text {
              width: 100%;
              height: 12rem;
              // background: olive;
              padding: 1rem;
              h3 {
                line-height: 3rem;
                text-align: left;
                color: rgba(48, 48, 51, 1);
              }
              p {
                font-size: 0.8rem;
                line-height: 1.6rem;
                text-align: left;
                color: rgba(144, 144, 153, 1);
              }
            }
          }
        }
        /deep/ .van-swipe__indicators {
          width: 100%;
          // background: rgb(240, 109, 65);
          display: flex;
          justify-content: center;
          i {
            margin: 0 10px;
            border-radius: 50%;
          }

          .van-swipe__indicator--active {
            background-color: rgba(11, 70, 150, 1) !important;
          }
          .van-swipe__indicator {
            background-color: rgba(221, 221, 221, 0.822);
            width: 0.7rem;
            height: 0.7rem;
          }
        }
      }
    }
    .main2 {
      width: 100%;
      height: auto;
      margin-top: 2rem;
      // background: brown;
      .top {
        h1 {
          font-size: 1.5rem;
          text-align: center;
        }
        .line {
          width: 14rem;
          height: 2rem;
          // background: chartreuse;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin: auto;
          div {
            float: left;
            margin: 0em 0.1em 0em 0.1em;
          }
          .min-line {
            height: 0.05rem;
            width: 3.5rem;
            background: rgba(11, 70, 150, 1);
          }
          .circular {
            width: 0.5rem;
            height: 0.5rem;
            background: rgba(11, 70, 150, 1);
            // border-radius: 50%;
            clip-path: circle(50%);
          }
        }
        p {
          width: 76%;
          margin: auto;
          text-align: center;
          line-height: 1.5rem;
          color: rgba(144, 144, 153, 1);
          font-size: 0.7rem;
        }
      }
      .bottom {
        width: 100%;
        height: 23rem;
        // background: blanchedalmond;
        margin-top: 2rem;
        padding: 0.6rem;
        ul {
          width: 100%;
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          li {
            width: 48.5%;
            height: 10rem;

            background: #fff;
            padding: 1rem;
            box-shadow: 0px 0px 0.5rem #d3d2d2;
            display: flex;
            .img {
              width: 2rem;
              height: 2rem;
              float: left;
              img {
                width: 100%;
                height: 100%;
              }
            }

            .text {
              flex: 1;
              float: left;
              // width: 6.8rem;
              height: 100%;
              // background: orangered;
              margin-left: 0.3rem;
              padding-top: 0.4rem;
              // background: red;
              span {
                width: 100%;
                height: 2rem;
                // color: rgba(11, 70, 150, 1);
                line-height: 1.5rem;
                font-size: 1.2rem;
                // background: orchid;
                font-weight: 550;
              }
              p {
                width: 90%;
                // background: #0b4696;
                color: rgba(144, 144, 153, 1);
                font-size: 0.8rem;
                line-height: 1.5rem;
                margin-top: 0.5rem;
              }
            }
          }
        }
      }
    }
    .main3 {
      width: 100%;
      height: 40rem;
      margin-top: 2rem;
      .top {
        // background: chartreuse;
        h1 {
          font-size: 1.5rem;
          text-align: center;
        }
        .line {
          width: 14rem;
          height: 2rem;
          // background: chartreuse;
          display: flex;
          justify-content: space-evenly;
          align-items: center;
          margin: auto;
          div {
            float: left;
            margin: 0em 0.1em 0em 0.1em;
          }
          .min-line {
            height: 0.05rem;
            width: 3.5rem;
            background: rgba(11, 70, 150, 1);
          }
          .circular {
            width: 0.5rem;
            height: 0.5rem;
            background: rgba(11, 70, 150, 1);
            clip-path: circle(50%);
          }
        }
        p {
          width: 76%;
          margin: auto;
          text-align: center;
          line-height: 1.5rem;
          color: rgba(144, 144, 153, 1);
          font-size: 0.7rem;
        }
      }
      .bottom {
        width: 100%;
        height: auto;
        overflow: hidden;
        // background: cornflowerblue;
        .my-swipe .van-swipe-item {
          height: 28rem;
          color: #fff;
          font-size: 1.25rem;
          line-height: 9.375rem;
          text-align: center;
          background-color: ;
          padding: 1rem;
          margin-top: 1rem;
          float: left;
          .box {
            width: 100%;
            height: 24rem;
            border-radius: 0.6rem;
            background: #fff;
            box-shadow: 0px 0px 13px rgb(218, 217, 217);
            .img {
              width: 100%;
              height: 13.4rem;
              position: relative;
              // overflow: hidden;
              img {
                width: 100%;
                height: 13.2rem;
              }
              .min-logo {
                width: 6rem;
                height: 1.6rem;
                background: rgba(11, 70, 150, 1);
                position: absolute;
                left: 1rem;
                bottom: -0.7rem;
                font-size: 0.75rem;
                line-height: 1.6rem;
                // z-index: 999;
              }
            }
            // .img1 {
            //   background: url("../../assets/img/news1-1.png") no-repeat;
            //   background-size: 100% 100%;
            // }
            // .img2 {
            //   background: url("../../assets/img/news1-2.png") no-repeat;
            //   background-size: 100% 100%;
            // }
            // .img3 {
            //   background: url("../../assets/img/news1-3.png") no-repeat;
            //   background-size: 100% 100%;
            // }
            .text {
              width: 100%;
              height: 12rem;
              // background: olive;
              padding: 1rem;
              h3 {
                line-height: 3rem;
                text-align: left;
                color: rgba(48, 48, 51, 1);
              }
              .line {
                width: 5rem;
                height: 0.15rem;
                background: rgba(11, 70, 150, 1);
                margin-bottom: 1rem;
              }
              p {
                font-size: 0.8rem;
                line-height: 1.6rem;
                text-align: left;
                color: rgba(144, 144, 153, 1);
              }
            }
          }
        }
        /deep/ .van-swipe__indicators {
          width: 100%;
          // background: rgb(240, 109, 65);
          display: flex;
          justify-content: center;
          i {
            margin: 0 10px;
            border-radius: 50%;
          }

          .van-swipe__indicator--active {
            background-color: rgba(11, 70, 150, 1) !important;
          }
          .van-swipe__indicator {
            background-color: rgba(221, 221, 221, 0.822);
            width: 0.7rem;
            height: 0.7rem;
            // margin: 0 0.5rem 0 0.5rem;
          }
        }
      }
    }
  }
}
</style>
