import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "../src/assets/css/reset.css";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "../src/assets/iconfont/iconfont.css";
// import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "vant";
import { Swipe, SwipeItem } from "vant";
import { Lazyload } from "vant";
import VueTouch from "vue-touch";
import { Pagination } from "vant";
import { Icon } from "vant";

Vue.use(Icon);

Vue.use(Pagination);
//左右滑动插件
Vue.use(VueTouch, { name: "v-touch" });
Vue.use(ElementUI);
Vue.use(Lazyload);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(Button);
Vue.use(ElementUI);
Vue.config.productionTip = false;

//vue对sessionStorage的监听
Vue.prototype.resetSetItem = function (key, newVal) {
  if (key === "drawershow") {
    var newStorageEvent = document.createEvent("StorageEvent"); // 创建一个StorageEvent事件
    const storage = {
      setItem: function (k, val) {
        sessionStorage.setItem(k, val);
        newStorageEvent.initStorageEvent(
          "setItem",
          false,
          false,
          k,
          null,
          val,
          null,
          null
        ); // 初始化创建的事件
        window.dispatchEvent(newStorageEvent); // 派发对象
      },
    };
    return storage.setItem(key, newVal);
  }
};

// 在main.js中采用全局守卫设置高度距离，在网上查找了很多，都不行，总结的方法
router.beforeEach((to, from, next) => {
  // 让页面回到顶部
  // chrome
  document.body.scrollTop = 0;
  // firefox
  document.documentElement.scrollTop = 0;
  // safari
  window.pageYOffset = 0;
  // 调用 next()，一定要调用 next 方法，否则钩子就不会被销毁
  //  这三句很重要，放进导航首位里面，就成功了，希望对你有帮助
  next();
});
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
