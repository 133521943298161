<template>
  <div class="main">
    <div class="text">
      <h1>服务目标</h1>
      <div>
        <p>
          宁明软件拥有专业的技术服务队伍，服务人员均具备大型企业的技术背景，能为客户提供过硬、优质的技术保障。
        </p>
        <p>实行区域管理，就近响应，可快速响应客户的需求；</p>
        <p>可结合客户的需求，为客户做针对性的定制化解决方案；</p>
        <p>有完善的年度服务报告，为客户提供更贴心，更周到的服务；</p>
        <p>
          对各种产品、流程的规范培训，鼓励员工不断学习深造，来保证一流的服务品质，打造高效的技术客服团队。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.main {
  .text {
    width: 85%;
    margin: 1rem auto;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 5rem;
    }
    div {
      margin-bottom: 1rem;
      h3 {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.788);
      }
      p {
        font-size: 0.9rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.664);
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
          "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        margin-bottom: 1rem;
      }
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }
}
</style>
