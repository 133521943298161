<template>
  <div class="footer">
    <!-- 这个是pc端的 -->
    <div
      class="footer-pc"
      v-if="drawershow == 'true'"
    >
      <div class="top">
        <div class="content">
          <div>
            <router-link to="/">
              <img
                class="images"
                :src="logo"
                alt=""
              />
            </router-link>

            <p>
              {{ logoText }}
            </p>
          </div>
          <div>
            <ul>
              <li>
                <a>产品创新</a>
              </li>
              <li
                v-for="(item, index) in listObj1"
                :key="index"
              >
                <a
                  @click="tohome2(item)"
                  class="el-icon-arrow-right"
                >{{
                  item.title
                }}</a>
              </li>
            </ul>
          </div>
          <div>
            <ul>
              <li><a>服务支持</a></li>
              <li
                v-for="(item, index) in listObj2"
                :key="index"
              >
                <a
                  @click="tohome3(item)"
                  class="el-icon-arrow-right"
                >{{
                  item.title
                }}</a>
              </li>
            </ul>
          </div>
          <div>
            <div class="code1">
              <img
                :src="csCode"
                alt=""
              />
              <p>{{ csCodeText }}</p>
            </div>
            <div class="code2">
              <img
                :src="oaCode"
                alt=""
              />
              <p style="font-size: 10px">{{ oaCodeText1 }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="line">
        <div class="min-line"></div>
      </div>
      <div class="bottom">
        <p>
          {{ copyright }}
          <a
            class="textUrl"
            href="https://beian.miit.gov.cn/#/Integrated/index"
            style="color: #606066"
          >
            沪ICP备15031158号-1</a>
        </p>
      </div>
    </div>
    <!-- 这个是手机端的 -->
    <div
      class="footer-mobile"
      v-else
    >
      <div class="top">
        <div class="footer1">
          <p>产品创新</p>
          <ul>
            <li
              v-for="(item, index) in listObj1"
              :key="index"
              @click="tohome2(item)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="footer2">
          <p>服务支持</p>
          <ul>
            <li
              v-for="(item, index) in listObj2"
              :key="index"
              @click="tohome3(item)"
            >
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="footer3">
          <span>
            <div class="img1 img"></div>
            <p>扫码添加客服咨询</p>
          </span>
          <span>
            <div class="img2 img"></div>
            <p>扫码关注官方公众号</p>
          </span>
        </div>
      </div>
      <div class="bottom">
        <router-link
          tag="div"
          to="/"
        ></router-link>
        <p>
          Copyright @ 2006-2021上海宁明软件科技有限公司 沪ICP备 15031158号-1
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/policy";

export default {
  // props: ["drawershow"],
  data () {
    return {
      logo: require("../../assets/img/shnm-logo-bottom.svg"),
      logoText:
        "上海宁明软件科技有限公司创始于2006年，是一家以软件技术为核心，主要提供行业解决方案、数字化产品工程解决方案及相关软硬件产品、平台及服务的公司。",
      listObj1: [
        // { name: "产品创新", url: "/Home/Home2" },
        // { name: "> CA 数字证书", url: "/Home/Home2" },
        // { name: "> 软件开发", url: "/Home/Home2" },
        // { name: "> 区块链技术", url: "/Home/Home2" },
      ],
      listObj2: [
        // { name: "服务支持", url: "/Home/Home3" },
        // { name: "> CA 数字证书", url: "/Home/Home3" },
        // { name: "> 软件开发", url: "/Home/Home3" },
        // { name: "> 区块链技术", url: "/Home/Home3" },
      ],
      csCode: require("../../assets/img/kf.png"),
      csCodeText: "扫码添加客服咨询",
      oaCode: require("../../assets/img/gs.png"),
      oaCodeText1: "扫码关注官方公众号",
      copyright: "Copyright @ 2006-2021上海宁明软件科技有限公司 ",
      drawershow: sessionStorage.getItem("drawershow"),
    };
  },
  created () {
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });

    var that = this;

    var data1 = { category: "4" };
    getList(data1).then((res) => {
      that.listObj1 = res.data.records;
    });
    var data2 = { category: "5" };
    getList(data2).then((res) => {
      that.listObj2 = res.data.records;
    });
  },
  methods: {
    tohome2 (item) {
      console.log(item, "8888888888888888");
      document.documentElement.scrollTop = 0;
      sessionStorage.setItem("home2", item.category2);
      this.$router.push("/home/home2" + "?title=" + item.category2);
      location.reload();
    },
    tohome3 (item) {
      document.documentElement.scrollTop = 0;
      console.log(item, "item");
      this.$router.push("/home/home3" + "?title=" + item.category2);
      sessionStorage.setItem("home3", item.category2);
      location.reload();
    },
  },
};
</script>

<style lang="less" scoped>
.footer-pc {
  width: 100%;
  height: 19.05rem;
  background: #010102;

  .top {
    width: 100%;
    height: 15rem;
    // background: url("../../assets/img/db.png") no-repeat;
    background-size: 100%;
    margin: auto;
    background: #010102;
    .content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      // background: chartreuse;
      display: flex;
      justify-content: space-between;
      align-items: center;
      // background: #010102;
      background: url("../../assets/img/db.png") no-repeat;
      div {
        width: 7.5rem;
        height: 10rem;
        // background: cornsilk;
      }
      div:nth-child(1) {
        width: 20.5rem;
        .images {
          height: 2rem;
          margin-bottom: 1rem;
        }

        p {
          width: 17.5rem;
          color: rgba(132, 132, 132, 1);
          font-size: 0.5rem;
          line-height: 1.5rem;
        }
      }
      div:nth-child(2),
      div:nth-child(3) {
        p:nth-child(1) {
          font-size: 1rem;
          font-weight: 600;
          margin-bottom: 2.5rem;
          color: rgba(204, 204, 204, 1);
        }

        ul {
          width: 100%;
          height: 100%;
          li:nth-child(1) {
            margin-bottom: 0.8vw;
            a {
              color: rgba(204, 204, 204, 1);
              font-size: 1rem;
              font-weight: 550;
              user-select: none;
            }
          }
          li {
            width: 17.5rem;
            color: rgba(132, 132, 132, 1);
            font-size: 0.5rem;
            line-height: 1.5rem;
            a {
              color: rgba(132, 132, 132, 1);
            }
          }
        }
      }
      div:nth-child(4) {
        width: 16rem;
        margin-left: 7.5rem;
        display: flex;
        justify-content: space-between;
        .code1,
        .code2 {
          width: 7.5rem;
          // background: chartreuse;
          line-height: 1.5rem;
          img {
            width: 7.5rem;
            margin: 0;
          }
          p {
            text-align: center;
            color: rgba(204, 204, 204, 1);
            // background: chartreuse;
            width: 7.5rem;
          }
        }
      }
    }
  }
  .line {
    width: 100%;
    height: 1px;
    // color: #fd2a2a;
    background: #5a5a5a;
    .min-line {
      width: 60.2rem;
      height: 1px;
      background: #5a5a5a;
    }
  }
  .bottom {
    width: 60.2rem;
    height: 4rem;
    background: #010102;
    // border-top: 1px solid #5a5a5a;
    margin: auto;

    p {
      width: 100%;
      text-align: center;
      line-height: 4rem;
      color: rgba(96, 96, 102, 1);
      font-size: 0.6vw;
      // background: #010102;
    }
  }
}
.footer-mobile {
  width: 100%;
  height: auto;
  // background: rgb(168, 45, 45);
  .top {
    width: 100%;
    height: auto;
    background: rgba(32, 36, 43, 1);
    padding: 1.5rem 1.5rem 2rem 1.5rem;
    .footer1 {
      width: 100%;
      height: 5rem;
      // background: chartreuse;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      p {
        font-size: 1.1rem;
        color: rgba(107, 120, 144, 1);
      }
      ul {
        li {
          float: left;
          margin-right: 2rem;
          color: rgba(255, 255, 255, 1);
          font-size: 0.9rem;
        }
      }
    }
    .footer2 {
      width: 100%;
      height: 5rem;
      // background: rgba(128, 255, 0, 0.623);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      margin-bottom: 1rem;
      p {
        font-size: 1.1rem;
        color: rgba(107, 120, 144, 1);
      }
      ul {
        li {
          float: left;
          margin-right: 1.4rem;
          color: rgba(255, 255, 255, 1);
          font-size: 0.9rem;
        }
      }
    }
    .footer3 {
      width: 100%;
      height: 11rem;
      // background: rgb(165, 185, 145);
      display: flex;
      justify-content: space-evenly;
      padding-top: 1rem;
      span {
        display: block;
        width: 8rem;
        height: 11rem;
        // background: chocolate;
        float: left;
        // margin-left: 1rem;
        .img {
          width: 8rem;
          height: 8rem;
        }
        .img1 {
          background: url("../../assets/img/QRcode1.png") no-repeat;
          background-size: 100%;
        }
        .img2 {
          background: url("../../assets/img/QRcode2.png") no-repeat;
          background-size: 100%;
        }
        p {
          text-align: center;
          font-size: 0.8rem;
          line-height: 2rem;
          color: rgba(144, 144, 153, 1);
        }
      }
    }
  }
  .bottom {
    width: 100%;
    height: auto;
    background: rgba(51, 51, 51, 1);
    padding: 1rem;
    div {
      width: 8rem;
      height: 2rem;
      background: url("../../assets/img/shnm-logo-bottom.svg") no-repeat;
      background-size: 100%;
      margin-bottom: 0.5rem;
    }
    p {
      width: 19rem;
      // height: 5rem;
      // background: orangered;
      color: rgba(96, 96, 102, 1);
      line-height: 1.5rem;
    }
  }
}
</style>
