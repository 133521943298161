<template>
  <div class="main">
    <div class="text">
      <h1>服务体系</h1>
      <div>
        <p>
          宁明软件始终将客户服务作为工作的重点，让客户真正感受到毅成软件科技是值得合作的企业。
        </p>
        <p>
          <span style="font-weight: 550">服务管理 :</span> <br />
          · 标准化：采用统一的服务流程； <br />
          ·体系化：建立完善的服务体系，来确保能够第一时间响应客户的服务需求；<br />
          ·自主化：主动了解用户产品的使用反馈。<br />
        </p>
        <p>
          <span style="font-weight: 550">服务方式 :</span> <br />
          · 通过电话、Email、在线客服支持;<br />
          · 回馈工单：可在线提交产品的任何使用问题，客服将回复和联系用户；<br />
          · 可以安排客服上门服务。<br />
        </p>
        <p>
          <span style="font-weight: 550"> 服务内容 :</span><br />
          · 对产品使用技术咨询;<br />
          · 提供产品技术资料及软件；<br />
          · 提供对软件产品的使用培训；<br />
          · 提供产品的维护和升级；<br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.main {
  .text {
    width: 85%;
    margin: 1rem auto;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 5rem;
    }
    div {
      margin-bottom: 1rem;
      h3 {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.788);
      }
      p {
        font-size: 0.9rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.664);
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
          "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        margin-bottom: 1rem;
      }
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }
}
</style>
