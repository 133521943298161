<template>
  <div class="main">
    <div class="text">
      <h1>服务网络</h1>
      <div>
        <p>
          宁明软件业务覆盖全国大多数省市区域，形成了以长江三角洲为中心辐射全国的运营服务体系。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.main {
  .text {
    width: 85%;
    margin: 1rem auto;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 5rem;
    }
    div {
      margin-bottom: 1rem;
      h3 {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.788);
      }
      p {
        font-size: 0.9rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.664);
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
          "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
      }
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }
}
</style>
