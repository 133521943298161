<template>
  <div class="Home6">
    <!-- pc端 -->
    <div
      class="news-pc"
      v-if="drawershow == 'true'"
    >
      <Main
        :name="name"
        :drawershow="drawershow"
      />
    </div>
    <!-- 手机端 -->
    <div
      class="news-mobile"
      v-else
    >
      <!-- <div class="title">
        <div class="img"></div>
      </div> -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Main from "../components/Home6/Main.vue";
import store from "../store";
export default {
  data () {
    return {
      name: "4",
      drawershow: sessionStorage.getItem("drawershow"),
    };
  },
  components: {
    Main,
  },
  beforeCreate () {
    const that = this;
    if (sessionStorage.getItem("drawershow")) {
      //当他有值的时候
      console.log(1);
    } else {
      //当他没有值的时候
      window.screenWidth = document.documentElement.clientWidth;
      if (window.screenWidth >= 992) {
        this.resetSetItem("drawershow", true);

        store.commit("SET_DRAWERSHOW", true);
      } else if (that.screenWidth < 992) {
        this.resetSetItem("drawershow", false);

        store.commit("SET_DRAWERSHOW", false);
      }
    }
  },
  created () {
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });
  },
  mounted () {
    console.log(this.$route.name, "4");
    this.name = this.$route.name;
  },
};
</script>

<style lang="less" scoped>
.Home6 {
  height: auto;
  .news-mobile {
  }
}
</style>
