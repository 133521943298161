<template>
  <div class="main">
    <!-- pc端 -->
    <div
      class="main-pc"
      v-if="drawershow == 'true'"
    >
      <div class="main1 main-box">
        <div class="main1-content">
          <div>
            <span>{{ name }}</span>&nbsp;&nbsp;&nbsp;
            <span>{{ main1.subTitle }}</span>
          </div>
          <div>
            <span>
              <router-link to="/">首页</router-link>&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;
            </span>
            <span>{{ name }}</span>
          </div>
        </div>
      </div>
      <div class="main2 main-box">
        <img
          :src="main2.img"
          alt=""
        />
        <div class="text">
          <p>{{ main2.title }}</p>
          <p v-html="main2.text"></p>
        </div>
      </div>
      <div class="main3 main-box">
        <div class="mian3-content">
          <div class="title">
            <p>{{ main3.title }}</p>
            <p>{{ main3.text }}</p>
          </div>
          <div class="content">
            <div class="min-logo">
              <span>&nbsp; ,,</span>
            </div>
            <el-carousel indicator-position="outside">
              <el-carousel-item
                v-for="(item, index) in listObj"
                :key="index"
              >
                <div class="left">
                  <p>{{ item.text1 }}</p>
                  <img
                    :src="item.img1"
                    alt=""
                  />
                  <span class="span1">{{ item.name1 }}</span>
                  <span class="span2">{{ item.position1 }}</span>
                </div>
                <div class="middle"></div>
                <div class="right">
                  <p>{{ item.text2 }}</p>
                  <img
                    :src="item.img2"
                    alt=""
                  />
                  <span class="span1">{{ item.name2 }}</span>
                  <span class="span2">{{ item.position2 }}</span>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
      <div class="main4 main-box">
        <h1 style="text-align: center; font-size: 30px">为什么选择我们</h1>
        <div class="left">
          <ul>
            <li>
              <img
                src="../../assets/img/tc67.png"
                alt=""
              />
              <div class="text">
                <p>快速服务111反馈</p>
                <p>
                  公司配备了专业的售后技术客服团队，面对客户遇到的问题可提供快速的问题处理方案，
                  保证客户系统的稳定高效。
                </p>
              </div>
            </li>
            <li>
              <img
                src="../../assets/img/tc66.png"
                alt=""
              />
              <div class="text">
                <p>专业优质团队</p>
                <p>
                  公司由曾服务于大型企业的IT技术团队和UI、UE设计团队、互联网精准销售、售后客服
                  团队组成，为企业和机构通往移动互联网搭建稳固桥梁。
                </p>
              </div>
            </li>
          </ul>
        </div>
        <div class="right">
          <ul>
            <li>
              <img
                src="../../assets/img/tc65.png"
                alt=""
              />
              <div class="text">
                <p>引领行业创新</p>
                <p>
                  为客户打造开放、灵活、可靠的数字平台，激发行业创新和产业升级，创造优质产品，推动数字产业发展。
                </p>
              </div>
            </li>
            <li>
              <img
                src="../../assets/img/tc68.png"
                alt=""
              />
              <div class="text">
                <p>在线服务支持</p>
                <p>
                  公司提供在线咨询服务，不管是售前的产品咨询了解还是售后的问题解答，都可以及时
                  在线咨询，快速高效。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="main5 main-box">
        <div class="main5-content">
          <p>致电400-105-1009获取您的专属解决方案</p>
          <p>
            丰富的行业纵深，无论您的企业处在什么样的发展阶段，都可以获得相应的方案设计
          </p>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div
      class="main-mobile"
      v-else
    >
      <div class="title">
        <div class="img"></div>
      </div>
      <div class="content">
        <div class="content1">
          <h1>关于宁明软件科技</h1>
          <div class="img"></div>
          <p>
            上海宁明软件科技有限公司创始于2006年，是一家以软件技术为核心，主要提供行业解决方案、数字化产品工程解决方案及相关软硬件产品、平台及服务的公司，在软件与行业应用的结合、软件与数字化产品的结合和咨询服务方面形成了宁明独特的经营模式和服务特色。上海宁明软件科技有限公司坐落于美丽而开放的国际大都市——上海，开放而高效的城市氛围造就了宁明人特有的工作态度。我们以客户为中心，为全国多家中小企业完成复杂的业务系统设计、开发、培训。上海宁明以人为本，积极培养企业管理和软件技术人员，多次组织人员参加著名管理专家的教育培训。上海宁明软件科技有限公司拥有软件自主知识产权，并提出了“用生命捍卫品质”的口号，公司研发团队有多年的项目实施经验，获得客户100%的好评。上海宁明软件科技有限公司期待与您一起成长，积极探索出了更利于企业、渠道、顾客等多方利益的运作模式，创造出更加美好的未来。宁明认为，随着软件技术的发展和在应用领域的拓展，软件将会成为社会和人们生活的一部分。宁明以软件应用的不同表现形式和通过相关业务的整合来为客户提供系统的、完善的服务，创造客户和社会的价值，进而实现我们的价值。
          </p>
        </div>
        <div class="content2">
          <h1>客户评价</h1>
          <p>
            宁明软件始终认真面对每一个项目，真诚面对每一个
            客户，不忘初心努力成长的姿态广受客户好评
          </p>
          <div class="Mask">
            <van-swipe
              class="my-swipe"
              :autoplay="3000"
              indicator-color="white"
            >
              <van-swipe-item>
                <div class="rotationMap">
                  <div class="min-box1">
                    <div class="min-logo">
                      <p>,,</p>
                    </div>
                    <p>
                      项目前期没有自己的技术团队，幸遇宁明软件团队，以高质量，高效率完成了项目开发任务，团队一片和谐，创始人亲自督导，认真负责，非常优秀的一支软件开发团队，是互联网界的一支清流，用户好评如潮！
                    </p>
                    <div class="img"></div>
                    <p class="p1">Alex Chen</p>
                    <p>CEO</p>
                  </div>
                  <div class="min-box2">
                    <div class="min-logo">
                      <p>,,</p>
                    </div>
                    <p>
                      专注，专业，高效，这三个词语很适合宁明软件团队，在软件定制开发领域中，宁明软件可以说是非常优秀的！而且对客户认真负责的态度，让我很意外！希望未来能成为行业的领军者！加油！
                    </p>
                    <div class="img1 img"></div>
                    <p class="p1">Jack Ma</p>
                    <p>CEO</p>
                  </div>
                </div>
              </van-swipe-item>
              <van-swipe-item>
                <div class="rotationMap">
                  <div class="min-box1">
                    <div class="min-logo">
                      <p>,,</p>
                    </div>
                    <p>
                      企业内部管理系统依托了上海宁明的服务，向企业内部各部门提供了一系列的业务管理服务，真正做到了线上一站式管理，上海宁明的专业团队为海量数据的安全性及稳定性提供了有力保障。
                    </p>
                    <div class="img2 img"></div>
                    <p class="p1">Stream Liu</p>
                    <p>CTO</p>
                  </div>
                  <div class="min-box2">
                    <div class="min-logo">
                      <p>,,</p>
                    </div>
                    <p>
                      在和上海宁明合作的这几年中，深刻的感受到宁明的的确确是一家做“云服务”的公司。在技术和服务品质上有一贯的追求是宁明的核心竞争力，同时在宁明的各个方面都体现着另一种竞争力——对服务的理解。
                    </p>
                    <div class="img3 img"></div>
                    <p class="p1">Jackson</p>
                    <p>CTO</p>
                  </div>
                </div>
              </van-swipe-item>
            </van-swipe>
          </div>
        </div>
        <div class="content3">
          <h1>为什么选择我们</h1>
          <div class="title-box"></div>
          <ul>
            <li>
              <div class="img">
                <img
                  src="../../assets/img/mobile5-1.png"
                  alt=""
                />
              </div>
              <div class="text">
                <h3>快速服务反馈</h3>
                <p>
                  公司配备了专业的售后技术客服团队，面对客户
                  遇到的问题可提供快速的问题处理方案，保证 客户系统的稳定高效。
                </p>
              </div>
            </li>
            <li>
              <div class="img">
                <img
                  src="../../assets/img/mobile5-2.png"
                  alt=""
                />
              </div>
              <div class="text">
                <h3>专业优质团队</h3>
                <p>
                  公司由曾服务于大型企业的IT技术团队和UI、UE
                  设计团队、互联网精准销售、售后客服团队组成，
                  为企业和机构通往移动互联网搭建稳固桥梁。
                </p>
              </div>
            </li>
            <li>
              <div class="img">
                <img
                  src="../../assets/img/mobile5-3.png"
                  alt=""
                />
              </div>
              <div class="text">
                <h3>在线服务支持</h3>
                <p>
                  公司提供在线咨询服务，不管是售前的产品咨询
                  了解还是售后的问题解答，都可以及时在线咨询， 快速高效。
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOne } from "@/api/policy";
import store from "../../store";
export default {
  props: ["name"],
  data () {
    return {
      main1: {
        title: "关于我们",
        subTitle: "ABOUT US",
      },
      main2: {
        img: require("../../assets/img/z4.png"),
        title: "关于宁明软件科技",
        text: "上海宁明软件科技有限公司创始于2006年，是一家以软件技术为核心，主要提供行业解决方案、数字化产品工程解决方案及相关软硬件产品、平台及服务的公司，在软件与行业应用的结合、软件与数字化产品的结合和咨询服务方面形成了宁明独特的经营模式和服务特色。上海宁明软件科技有限公司坐落于美丽而开放的国际大都市——上海，开放而高效的城市氛围造就了宁明人特有的工作态度。我们以客户为中心，为全国多家中小企业完成复杂的业务系统设计、开发、培训。上海宁明以人为本，积极培养企业管理和软件技术人员，多次组织人员参加著名管理专家的教育培训。上海宁明软件科技有限公司拥有软件自主知识产权，并提出了“用生命捍卫品质”的口号，公司研发团队有多年的项目实施经验，获得客户100%的好评。上海宁明软件科技有限公司期待与您一起成长，积极探索出了更利于企业、渠道、顾客等多方利益的运作模式，创造出更加美好的未来。宁明认为，随着软件技术的发展和在应用领域的拓展，软件将会成为社会和人们生活的一部分。宁明以软件应用的不同表现形式和通过相关业务的整合来为客户提供系统的、完善的服务，创造客户和社会的价值，进而实现我们的价值。",
      },
      main3: {
        title: "客户评价",
        text: "宁明软件始终认真面对每一个项目，真诚面对每一个客户，不忘初心努力成长的姿态广受客户好评",
      },
      listObj: [
        {
          id: 1,
          name1: "Alex Chen",
          name2: "Jack Ma",
          position1: "CEO",
          position2: "CEO",
          text1:
            "项目前期没有自己的技术团队，幸遇宁明软件团队，以高质量，高效率完成了项目开发任务，团队一片和谐，创始人亲自督导，认真负责，非常优秀的一支软件开发团队，是互联网界的一支清流，用户好评如潮！",
          text2:
            "专注，专业，高效，这三个词语很适合宁明软件团队，在软件定制开发领域中，宁明软件可以说是非常优秀的！而且对客户认真负责的态度，让我很意外！希望未来能成为行业的领军者！加油！",
          img1: require("../../assets/img/people-01.png"),
          img2: require("../../assets/img/people-02.png"),
        },
        {
          id: 2,
          name1: "Stream Liu",
          name2: "Jackson",
          position1: "CTO",
          position2: "CTO",
          text1:
            "企业内部管理系统依托了上海宁明的服务，向企业内部各部门提供了一系列的业务管理服务，真正做到了线上一站式管理，上海宁明的专业团队为海量数据的安全性及稳定性提供了有力保障。",
          text2:
            "在和上海宁明合作的这几年中，深刻的感受到宁明的的确确是一家做“云服务”的公司。在技术和服务品质上有一贯的追求是宁明的核心竞争力，同时在宁明的各个方面都体现着另一种竞争力——对服务的理解。",
          img1: require("../../assets/img/people-03.png"),
          img2: require("../../assets/img/people-04.png"),
        },
      ],
      activeName: "1",
      drawershow: sessionStorage.getItem("drawershow"), //是否打开pc端样式
    };
  },
  beforeCreate () {
    const that = this;
    if (sessionStorage.getItem("drawershow")) {
      //当他有值的时候
      console.log(1);
    } else {
      //当他没有值的时候
      window.screenWidth = document.documentElement.clientWidth;
      if (window.screenWidth >= 992) {
        this.resetSetItem("drawershow", true);

        store.commit("SET_DRAWERSHOW", true);
      } else if (that.screenWidth < 992) {
        this.resetSetItem("drawershow", false);

        store.commit("SET_DRAWERSHOW", false);
      }
    }
  },
  created () {
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });
    getOne(8).then((res) => {
      this.main2.text = res.data.content;
    });
  },
};
</script>

<style lang="less" scoped>
.main-pc {
  width: 100%;
  height: auto;
  background: #fff;
  .main-box {
    width: 60.2rem;
    margin: auto;
  }
  .main1 {
    width: 100%;
    height: 6rem;
    background: #f8f8f8;
    .main1-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      background: #f8f8f8;
      // background: crimson;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(1) {
        span:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 550;
          letter-spacing: 1px;
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          letter-spacing: 1px;
        }
      }
      div:nth-child(2) {
        span:nth-child(1) {
          font-size: 0.7rem;
          letter-spacing: 1px;
          a {
            color: black;
          }
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          font-weight: 550;
          color: #0b4696;
          letter-spacing: 1px;
        }
      }
    }
  }
  .main2 {
    box-sizing: border-box;
    height: auto;
    // background: palegoldenrod;
    padding-top: 5.85rem;
    padding-bottom: 6.95rem;
    display: flex;
    justify-content: space-between;
    // background: rgb(223, 18, 18);
    img {
      float: left;
      width: 21.3rem;
    }
    .text {
      width: 37.5rem;
      float: left;
      p:nth-child(1) {
        font-size: 1.5rem;
        margin-bottom: 1.5rem;
        font-weight: 550;
      }
      p:nth-child(2) {
        font-size: 0.6rem;
        line-height: 1.5rem;
        color: #848484;
      }
    }
  }
  .main3 {
    width: 100%;
    height: 40.15rem;
    background: url("../../assets/img/evaluationBackground.png") no-repeat;
    background-size: 100% 100%;
    // position: absolute;
    // left: 0;
    .mian3-content {
      width: 60.2rem;
      height: auto;
      // background: lightcoral;
      // background: url("../../assets/img/evaluationBackground.png") no-repeat;
      // background-size: 100% 100%;
      margin: auto;
      padding: 4rem 0px 5rem 0px;
      .title {
        width: 100%;
        height: 100px;
        // background: lightgray;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        p:nth-child(1) {
          font-size: 1.5rem;
          color: #fff;
        }
        p:nth-child(2) {
          color: rgba(196, 204, 208, 1);
        }
      }
      .content {
        width: 100%;
        height: 26.5rem;
        // background: lightgreen;
        margin-top: 0.75rem;
        position: relative;
        .min-logo {
          position: absolute;
          top: 0.9rem;
          left: 50%;
          transform: translateX(-50%);
          width: 4.55rem;
          height: 4.55rem;
          border-radius: 50%;
          background: rgba(11, 70, 150, 1);
          z-index: 111;
          user-select: none;
          span {
            font-size: 3.5rem;
            font-family: Georgia, "Times New Roman", Times, serif;
            color: #fff;
            // background: tan;
            line-height: 2rem;
            width: 3.5rem;
            height: 3.5rem;
            display: inline-block;
            text-align: center;
            letter-spacing: -0.25rem;
          }
        }
        //----------------------
        //这个是ui组件相关的代码
        .el-carousel {
          position: absolute;
          top: 5rem;
          left: 0;
          width: 100%;
          border-radius: 0.25rem;
        }
        /deep/.el-carousel__container {
          height: 16.25rem;
        }
        // /deep/.el-carousel__item {
        //   height: 325px !important;
        // }
        .el-carousel__item .left {
          width: 30rem;
          height: 100%;
          // background: cornflowerblue;
          float: left;
          padding: 1.5rem 2.75rem 1.5rem 2.75rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          p {
            text-align: center;
            font-size: 0.5rem;
            line-height: 1.25rem;
            color: rgba(196, 204, 208, 1);
          }
          img {
            width: 4rem;
          }
          .span1 {
            color: rgba(11, 70, 150, 1);
            font-weight: 550;
            font-size: 0.8rem;
          }
          .span2 {
            color: #fff;
          }
        }
        .el-carousel__item .middle {
          width: 1px;
          height: 60%;
          margin-top: 5%;
          background: #fff;
          float: left;
        }
        .el-carousel__item .right {
          width: 50%;
          height: 100%;
          // background: rgb(161, 189, 241);
          float: left;
          padding: 1.5rem 2.75rem 1.5rem 2.75rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          p {
            text-align: center;
            font-size: 0.5rem;
            line-height: 1.25rem;
            color: rgba(196, 204, 208, 1);
          }
          img {
            width: 4rem;
          }
          .span1 {
            color: rgba(11, 70, 150, 1);
            font-weight: 550;
            font-size: 0.8rem;
          }
          .span2 {
            color: #fff;
          }
        }
        /deep/.el-carousel__indicators--outside {
          margin-top: 2.5rem;
        }
        /deep/.el-carousel__button {
          width: 0.5rem !important;
          height: 0.5rem !important;
          border-radius: 50%;
          background: #fff !important;
        }
        /deep/.el-carousel__indicators--outside button {
          background: rgba(11, 70, 150, 1) !important;
        }
        /deep/.el-carousel__indicator.is-active button {
          width: 1.5rem !important;
          border-radius: 25%;
          background: rgba(11, 70, 150, 1) !important;
        }

        /deep/ .el-carousel__indicators--outside button {
          background: #fff !important;
        }
        .el-carousel__item:nth-child(3) {
          background-color: rgba(0, 0, 0, 0.4);
        }
        .el-carousel__item:nth-child(4) {
          background-color: rgba(0, 0, 0, 0.4);
        }

        .el-carousel__item:nth-child(5) {
          background-color: rgba(0, 0, 0, 0.4);
        }
        //----------------------
      }
    }
  }
  .main4 {
    height: 27.5rem;
    background: #fff;
    // margin-top: 112px;
    padding-top: 5.6rem;
    padding-bottom: 7.5rem;
    // margin-bottom: 64px;
    .left {
      width: 50%;
      height: auto;
      // background: #fff;
      float: left;
      h1 {
        width: 12rem;
        font-size: 1.5rem;
        letter-spacing: 0.2rem;
        line-height: 3rem;
        border-bottom: 0.1rem solid rgba(11, 70, 150, 1);
      }
      ul {
        width: 100%;
        height: auto;
        // background: lemonchiffon;
        margin-top: 2.85rem;
        li {
          width: 100%;
          height: 6rem;
          // background: lightcoral;
          margin-bottom: 0.5rem;
        }
        img {
          float: left;
          margin-top: 0.5rem;
        }
        .text {
          float: left;
          width: 25rem;
          height: 100%;
          // background: lightcyan;
          margin-left: 1rem;
          p:nth-child(1) {
            font-size: 1rem;
            font-weight: 550;
            line-height: 2rem;
            letter-spacing: 1px;
          }
          p:nth-child(2) {
            font-size: 0.7rem;
            line-height: 1.5rem;
            color: rgba(155, 155, 155, 1);
          }
        }
      }
    }
    .right {
      width: 50%;
      height: auto;
      // background: lightgreen;
      float: right;
      h1 {
        width: 12rem;
        font-size: 1.5rem;
        letter-spacing: 0.2rem;
        line-height: 3rem;
        border-bottom: 0.1rem solid rgba(11, 70, 150, 1);
      }
      ul {
        width: 100%;
        height: auto;
        // background: lemonchiffon;
        margin-top: 2.85rem;
        li {
          width: 100%;
          height: 6rem;
          // background: lightcoral;
          margin-bottom: 0.5rem;
        }
        img {
          float: left;
          margin-top: 0.5rem;
        }
        .text {
          float: left;
          width: 25rem;
          height: 100%;
          // background: lightcyan;
          margin-left: 1rem;
          p:nth-child(1) {
            font-size: 1rem;
            font-weight: 550;
            line-height: 2rem;
            letter-spacing: 1px;
          }
          p:nth-child(2) {
            font-size: 0.7rem;
            line-height: 1.5rem;
            color: rgba(155, 155, 155, 1);
          }
        }
      }
    }
    // .right {
    //   width: 50%;
    //   height: auto;
    //   // background: rgb(195, 241, 195);
    //   float: left;
    //   h1 {
    //     width: 240px;
    //     font-size: 30px;
    //     letter-spacing: 4px;
    //     line-height: 60px;
    //     border-bottom: 2px solid rgba(11, 70, 150, 1);
    //   }
    //   ul {
    //     width: 100%;
    //     height: auto;
    //     // background: lemonchiffon;
    //     margin-top: 57px;
    //     li {
    //       height: 51px;
    //       width: 100%;
    //       margin-bottom: 13px;
    //       border: 1px solid rgba(229, 229, 229, 1);
    //       padding: 15px 27px 15px 27px;
    //       overflow: hidden;
    //       background: #fff;
    //       p:nth-child(1) {
    //         font-size: 18px;
    //         line-height: 18px;
    //         margin-bottom: 25px;
    //         span {
    //           font-size: 22px;
    //           float: right;
    //           font-weight: 600;
    //         }
    //       }
    //       p:nth-child(2) {
    //         font-size: 14px;
    //         line-height: 30px;
    //         letter-spacing: 1px;
    //         color: rgba(155, 155, 155, 1);
    //         // margin-bottom: 25px;
    //       }
    //     }
    //     li:nth-child(4) {
    //       margin-bottom: 10px;
    //     }
    //     li:hover {
    //       height: 188px;
    //       transition: height 0.5s linear 0s;
    //       p:nth-child(1) {
    //         color: rgba(11, 70, 150, 1);
    //       }
    //     }
    //   }
    //   /deep/.el-collapse {
    //     // color: blue;
    //     margin-top: 60px;
    //   }
    //   /deep/.el-icon-arrow-right:before {
    //     content: "\e61f";
    //     width: 16px;
    //     height: 16px;
    //     font-family: "iconfont";
    //     display: inline-block;
    //     float: right;
    //     line-height: 16px;
    //     text-align: center;
    //     font-size: 16px;
    //     user-select: none;
    //     // background: blueviolet;
    //   }
    //   /deep/.is-active {
    //     // background: cadetblue;
    //     .el-collapse-item__header {
    //       color: rgba(11, 70, 150, 1);
    //       .el-icon-arrow-right:before {
    //         content: "\e638";
    //       }
    //     }
    //   }
    //   /deep/.el-collapse-item__header {
    //     border: 0px solid #ebeef5;
    //     font-size: 16px;

    //     // margin-bottom: 10px;
    //   }
    //   /deep/.el-collapse-item {
    //     border: 1px solid #ebeef5;
    //     margin-bottom: 15px;
    //     padding: 4px 20px 4px 20px;
    //   }
    //   /deep/ .el-collapse-item__wrap {
    //     border-bottom: 0px solid #ebeef5;
    //   }
    //   /deep/.el-collapse-item__content {
    //     color: #9b9b9b;
    //   }
    //   .el-collapse {
    //     border: 0px;
    //   }
    // }
  }

  .main5 {
    height: 11rem;
    width: 100%;
    background: #f8f8f8;
    .main5-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      p:nth-child(1) {
        font-size: 1.5rem;
        // font-weight: 600;
        // line-height: 4vw;
      }
      p:nth-child(2) {
        font-size: 0.8rem;
        letter-spacing: 0.2rem;
        margin-top: 1rem;
        color: #848484;
      }
    }
  }
}
.main-mobile {
  .title {
    width: 100%;
    height: auto;
    // background: blanchedalmond;
    .img {
      width: 100%;
      height: 43vw;
      background: url("../../assets/img/mobile6-1.png") no-repeat;
      background-size: 100%;
      // border: 1px solid red;
    }
  }
  .content {
    width: 100%;
    height: auto;
    // background: olivedrab;

    .content1 {
      width: 100%;
      height: auto;
      background: #fff;
      padding: 0.7rem 2rem 0.7rem 2rem;
      h1 {
        font-size: 1.5rem;
        text-align: center;
        line-height: 5rem;
      }
      p {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.664);
        margin-top: 1.5rem;
        margin-bottom: 1.5rem;
        text-indent: 1.5rem;
        text-align: justify;
      }
      .img {
        width: 100%;
        height: 38vw;
        background: url("../../assets/img/mobile6-2.png") no-repeat;
        background-size: 100% 100%;
        // border: 1px solid black;
      }
    }
    .content2 {
      width: 100%;
      height: 49rem;
      padding: 1.5rem;
      background: url("../../assets/img/mobile6-3.png") no-repeat;
      background-size: 100% 100%;
      // border: 1px solid black;

      h1 {
        font-size: 1.5rem;
        text-align: center;
        color: #fff;
        line-height: 3rem;
      }
      p {
        width: 88%;
        color: rgba(196, 204, 208, 1);
        text-align: center;
        margin: auto;
        line-height: 1.5rem;
        font-size: 0.7rem;
      }
      .Mask {
        width: 100%;
        overflow: hidden;
        .my-swipe .van-swipe-item {
          color: #fff;
          font-size: 20px;
          line-height: 150px;
          text-align: center;
          // background-color: #39a9ed;
          float: left;
          // border: 1px solid red;

          .rotationMap {
            // float: left;
            .min-box1,
            .min-box2 {
              width: 100%;
              height: 16.5rem;
              background: rgba(0, 0, 0, 0.5);
              margin-top: 3rem;
              position: relative;
              padding: 1rem;
              display: flex;
              justify-content: center;
              flex-direction: column;
              .min-logo {
                width: 3rem;
                height: 3rem;
                background: #0b4696;
                border-radius: 50%;
                position: absolute;
                top: -2rem;
                left: 45%;
                p {
                  font-size: 2rem;
                  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial,
                    sans-serif;
                }
              }
              p {
                width: 100%;
                margin: 0;
                color: rgba(196, 204, 208, 1);
              }
              .img {
                width: 4.5rem;
                height: 4.5rem;
                margin: 0.4rem auto;
                background: url("../../assets/img/people-01.png") no-repeat;
                background-size: 100%;
              }
              .img1 {
                background: url("../../assets/img/people-02.png") no-repeat;
                background-size: 100%;
              }
              .img2 {
                background: url("../../assets/img/people-03.png") no-repeat;
                background-size: 100%;
              }
              .img3 {
                background: url("../../assets/img/people-04.png") no-repeat;
                background-size: 100%;
              }
              .p1 {
                color: rgba(11, 70, 150, 1);
                font-size: 1rem;
              }
            }
          }
        }
      }
    }
    .content3 {
      width: 100%;
      height: auto;
      background: #fff;
      padding: 1.5rem 1.5rem 2.5rem 1.5rem;
      position: relative;
      .title-box {
        width: 4rem;
        height: 0.7rem;
        background: #cde2ff;
        border-radius: 1rem;
        position: absolute;
        top: 3rem;
      }
      h1 {
        font-size: 1rem;
        text-align: left;
        line-height: 2.5rem;
        position: absolute;
        z-index: 100;
      }
      ul {
        // padding: 1rem;
        box-shadow: 0px 0px 20px #e2e2e2;
        margin-top: 3.5rem;
        li {
          width: 100%;
          height: 7.5rem;
          border-bottom: 1px solid rgba(175, 175, 179, 0.199);
          // border: 1px solid#909099;
          .img {
            height: 100%;
            width: 25%;
            // background: blueviolet;
            float: left;
            display: flex;
            img {
              width: 5em;
              margin: auto;
            }
          }
          .text {
            height: 100%;
            width: 75%;
            // background: cadetblue;
            float: left;
            padding: 0.5rem;
            h3 {
              font-size: 1rem;
              line-height: 2rem;
            }
            p {
              color: rgba(144, 144, 153, 1);
              line-height: 1.3rem;
              overflow: hidden;
              height: 4rem;
              text-overflow: ellipsis;
              font-size: 0.7rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              text-align: justify;
            }
          }
        }
      }
    }
  }
}
</style>
