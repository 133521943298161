<template>
  <div class="main">
    <!-- pc端 -->
    <div
      class="main-pc"
      v-if="drawershow == 'true'"
    >
      <div class="main1 main-box">
        <div class="main1-content">
          <div>
            <span>{{ name }}</span>&nbsp;&nbsp;&nbsp;
            <span>CONTACT US</span>
          </div>
          <div>
            <span>
              <router-link to="/">首页</router-link>&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;
            </span>
            <span>{{ name }}</span>
          </div>
        </div>
      </div>
      <div class="main2 main-box">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
          >
            <p>
              <span style="font-size: 24px">{{ item.title
                }}<span class="span2">{{ item.info }}</span></span>
              <el-button @click="dialogVisible = true">职位申请</el-button>
            </p>
            <p style="font-size: 15px; margin-top: 18px">
              <span>国假正常放假</span><span>缴纳保险</span><span>年终奖</span>
            </p>
            <p
              style="font-size: 20px; margin-top: 18px; line-height: 30px"
              v-html="item.content"
            ></p>
          </li>
        </ul>
      </div>

      <el-dialog
        title="联系方式"
        :visible.sync="dialogVisible"
        width="600px"
      >
        <!-- :before-close="handleClose" -->
        <p>QQ：2823396533</p>
        <p>简历和作品请发送至：2823396533@qq.com</p>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            plain
            @click="dialogVisible = false"
          >我知道了</el-button>
        </span>
      </el-dialog>
    </div>
    <!-- 手机端 -->
    <div
      class="main-mobile"
      v-else
    >
      <div class="main2 main-box">
        <ul>
          <li
            v-for="(item, index) in list"
            :key="index"
          >
            <p>
              <span style="font-size: 1.5rem">{{ item.title
                }}<span class="span2">{{ item.info }}</span></span>
            </p>

            <p style="font-size: 0.8rem; margin-top: 1rem">
              <span>国假正常放假</span><span>缴纳保险</span><span>年终奖</span>
            </p>
            <p
              style="font-size: 20px; margin-top: 18px; line-height: 2rem"
              v-html="item.content"
            ></p>
            <p style="margin-top: 18px">
              <el-button @click="dialogVisible = true">职位申请</el-button>
            </p>
          </li>
        </ul>
      </div>
      <el-dialog
        title="联系方式"
        :visible.sync="dialogVisible"
        width="90vw"
      >
        <!-- :before-close="handleClose" -->
        <p>QQ：2823396533</p>
        <p>简历和作品请发送至：2823396533@qq.com</p>
        <span
          slot="footer"
          class="dialog-footer"
        >
          <el-button
            plain
            @click="dialogVisible = false"
          >我知道了</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { getList } from "@/api/policy";

export default {
  props: ["name"],
  data () {
    return {
      dialogVisible: false,
      list: [],
      drawershow: sessionStorage.getItem("drawershow"), //是否打开pc端样式
    };
  },
  created () {
    var that = this;
    var data = { category: "7" };
    getList(data).then((res) => {
      that.list = res.data.records;
    });

    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });
  },
};
</script>

<style lang="less" scoped>
.main-pc {
  width: 100%;
  height: auto;
  background: #fff;
  .main-box {
    width: 60.2rem;
    margin: auto;
  }
  .main1 {
    width: 100%;
    height: 6rem;
    background: #f8f8f8;
    .main1-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      display: flex;
      background: #f8f8f8;
      justify-content: space-between;
      align-items: center;
      div:nth-child(1) {
        span:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 550;
          letter-spacing: 1px;
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          letter-spacing: 1px;
        }
      }
      div:nth-child(2) {
        span:nth-child(1) {
          font-size: 0.7rem;
          letter-spacing: 1px;
          a {
            color: black;
          }
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          font-weight: 550;
          color: #0b4696;
          letter-spacing: 1px;
        }
      }
    }
  }
  /deep/.main2 {
    height: auto;
    background: #fff;
    padding-top: 2.8rem;
    padding-bottom: 9.4rem;
    // border: 1px solid rgba(218, 224, 233, 1);
    ul {
      li {
        width: 100%;
        // height: 20px;
        // background: cadetblue;
        // margin-bottom: 20px;
        border-bottom: 1px solid rgb(247, 247, 247);
        padding: 2rem 0 2rem 0;
        // background: royalblue;
        p {
          color: rgba(102, 102, 102, 1);
        }
        p:nth-child(1) {
          font-weight: 550;
          font-size: 1rem;
          display: flex;
          justify-content: space-between;
          color: #000;
          .span2 {
            color: rgba(255, 167, 0, 1);
            margin-left: 1.5rem;
          }
        }
        p:nth-child(2) {
          span {
            background: rgba(0, 117, 251, 1);
            color: #fff;
            padding: 0.15rem 0.5rem 0.15rem 0.5rem;
            margin-right: 0.5rem;
          }
        }
        p:nth-child(3) {
          p {
            font-size: 0.75rem;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
          }
        }
        .title {
          font-weight: 550;
          line-height: 1.5rem;
        }
      }
    }
  }
  .main3 {
    height: 11rem;
    width: 100%;
    background: #f8f8f8;
    .main3-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      p:nth-child(1) {
        font-size: 1.5rem;
        // font-weight: 600;
        // line-height: 4vw;
      }
      p:nth-child(2) {
        font-size: 0.8rem;
        letter-spacing: 1px;
        margin-top: 0.5rem;
        color: #848484;
      }
    }
  }
  /deep/.el-dialog {
    font-weight: 550;
    text-align: center;
    // background: royalblue;
    P {
      text-align: center;
      font-weight: 500;
    }
    .el-button {
      display: block;
      margin: auto;
      width: 6.5rem;
    }
  }
}
.main-mobile {
  padding: 0.5rem;
  /deep/.main2 {
    height: auto;
    background: #fff;
    padding-top: 2.8rem;
    padding-bottom: 9.4rem;
    // border: 1px solid rgba(218, 224, 233, 1);
    ul {
      li {
        width: 100%;
        // height: 20px;
        // background: cadetblue;
        // margin-bottom: 20px;

        border-bottom: 1px solid rgb(247, 247, 247);
        padding: 2rem 2rem 2rem 2rem;
        // background: royalblue;
        p {
          color: rgba(102, 102, 102, 1);
        }
        p:nth-child(1) {
          font-weight: 550;
          font-size: 1rem;
          display: flex;
          justify-content: space-between;
          color: #000;
          .span2 {
            color: rgba(255, 167, 0, 1);
            margin-left: 1.5rem;
          }
        }
        p:nth-child(2) {
          span {
            background: rgba(0, 117, 251, 1);
            color: #fff;
            padding: 0.15rem 0.5rem 0.15rem 0.5rem;
            margin-right: 0.5rem;
          }
        }
        p:nth-child(3) {
          p {
            font-size: 0.75rem;
            font-weight: 500;
            color: rgba(102, 102, 102, 1);
          }
        }
        .title {
          font-weight: 550;
          line-height: 1.5rem;
        }
      }
    }
  }
  .main3 {
    height: 11rem;
    width: 100%;
    background: #f8f8f8;
    .main3-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      p:nth-child(1) {
        font-size: 1.5rem;
        // font-weight: 600;
        // line-height: 4vw;
      }
      p:nth-child(2) {
        font-size: 0.8rem;
        letter-spacing: 1px;
        margin-top: 0.5rem;
        color: #848484;
      }
    }
  }
  /deep/.el-dialog {
    font-weight: 550;
    text-align: center;
    // background: royalblue;
    P {
      text-align: center;
      font-weight: 500;
    }
    .el-button {
      display: block;
      margin: auto;
      width: 6.5rem;
    }
  }
}
</style>
