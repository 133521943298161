<template>
  <div class="main">
    <div class="text">
      <h1>国庆放假通知</h1>
      <span>2020-10-10 宁明编辑部</span>
      <div>
        <p>根据国家法定假期的规定,并结合公司实际情况,现对中秋假期做如下安排:</p>
      </div>
      <div>
        <!-- <img src="../../assets/img/组 12.png" alt="" /> -->
      </div>
      <div>
        <p>
          —、9月19日-9月21日,共放假三天,9月22日(周三)上班。<br />
          二、公司给每位员工发放节日福利为面值500元大润发购物卡一张，假前自行到行政办公室签字领取。<br />
          三、假期期间原则上不允许加班，项目组遇特殊情况加班的同事,务必提前向马总报备，经批准后方可进行加班。<br />
          四、各项目组安排好值班人员，务必保持手机畅通,遇有大事件发生,要及时上报告并妥善处理。放假期间,尽量避开人群聚集,请注意出行安全。<br />
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.main {
  .text {
    width: 85%;
    margin: 2.5rem auto;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 2.5rem;
    }
    span {
      display: block;
      margin-bottom: 2rem;
      color: rgba(144, 144, 153, 1);
      font-size: 0.8rem;
      // background: chartreuse;
      text-align: center;
    }
    div {
      margin-bottom: 1rem;
      h3 {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.788);
      }
      p {
        font-size: 0.9rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.664);
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
          "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
      }
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }
}
</style>
