import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Home1 from "../views/Home1.vue";
import Home2 from "../views/Home2.vue";
import Home3 from "../views/Home3.vue";
import Home4 from "../views/Home4.vue";
import Home5 from "../views/Home5.vue";
import Home6 from "../views/Home6.vue";
import Home7 from "../views/Home7.vue";
import page1 from "../components/Home6/Page1.vue";
import page2 from "../components/Home6/page2.vue";
import HomePage1 from "../components/Home2/page1.vue";
import HomePage2 from "../components/Home2/page2.vue";
import HomePage3 from "../components/Home2/page3.vue";
import HomePage4 from "../components/Home3/page4.vue";
import HomePage5 from "../components/Home3/page5.vue";
import HomePage6 from "../components/Home3/page6.vue";
import HomePage7 from "../components/Home3/page7.vue";
import HomePage8 from "../components/Home6/page8.vue";
import HomePage9 from "../components/Home6/page9.vue";
import HomePage10 from "../components/Home6/page10.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // name: "Home",
    component: Home,
    children: [
      {
        path: "/",
        name: "首页",
        component: Home1,
        meta: { title: "首页" },
      },
      {
        path: "/home/home2",
        name: "产品创新",
        component: Home2,
        meta: { title: "产品创新" },
        children: [
          {
            path: "/Home/Home2/HomePage1",
            name: "区块链技术",
            component: HomePage1,
          },
          {
            path: "/Home/Home2/HomePage2",
            name: "CA数字证书",
            component: HomePage2,
          },
          {
            path: "/Home/Home2/",
            name: "软件开发服务",
            component: HomePage3,
          },
        ],
      },
      {
        path: "/home/home3",
        name: "服务支持",
        component: Home3,
        meta: { title: "服务支持" },
        children: [
          {
            path: "/Home/Home3/HomePage4",
            name: "服务目标",
            component: HomePage4,
          },
          {
            path: "/Home/Home3/HomePage5",
            name: "服务体系",
            component: HomePage5,
          },
          {
            path: "/Home/Home3/HomePage6",
            name: "服务网络",
            component: HomePage6,
          },
          {
            path: "/Home/Home3/",
            name: "技术支持",
            component: HomePage7,
          },
        ],
      },

      {
        path: "/home/home4",
        name: "关于我们",
        component: Home4,
        meta: { title: "关于我们" },
      },

      {
        path: "/home/home5",
        name: "联系我们",
        component: Home5,
        meta: { title: "联系我们" },
      },
      {
        path: "/home/home6",
        name: "新闻中心",
        component: Home6,
        meta: { title: "新闻中心" },
        children: [
          {
            path: "/",
            name: "新闻中心",
            component: page1,
            meta: { title: "新闻中心" },
          },
          {
            path: "/home/home6/page2",
            name: "新闻中心",
            component: page2,
            meta: { title: "新闻中心" },
            children: [
              {
                path: "/home/home6/page2/HomePage8",
                name: "新闻中心",
                component: HomePage8,
              },
              {
                path: "/home/home6/page2/HomePage9",
                name: "新闻中心",
                component: HomePage9,
              },
              {
                path: "/home/home6/page2/HomePage10",
                name: "新闻中心",
                component: HomePage10,
              },
            ],
          },
        ],
      },
      {
        path: "/home/home7",
        name: "人才招聘",
        component: Home7,
        meta: { title: "人才招聘" },
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
