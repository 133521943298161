<template>
  <div class="header">
    <div class="content">
      <span>{{ headerText }}</span>
      <span><img
          :src="minLogo"
          alt=""
        /> &nbsp;{{ phone }}</span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      headerText: "您好，欢迎来到上海宁明软件科技有限公司！",
      minLogo: require("../../assets/img/icon.png"),
      phone: "400-105-1009",
      screenWidth: document.documentElement.clientWidth,
    };
  },
};
</script>

<style lang="less" scoped>
// 这边我们可以将它的宽度设置为rem 可变
.header {
  width: 100%;
  height: 58px;
  background: black;
  .content {
    width: 60.25rem;
    height: 100%;
    background: black;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    span {
      color: rgba(212, 218, 227, 1);
      font-size: 12px;
      letter-spacing: 1px;
      line-height: 58px;
      user-select: none;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .header {
    transition: all 0.5s linear;
    height: 0px;
  }
}
</style>
