<template>
  <div class="main">
    <!-- pc端 -->
    <div
      class="main-pc"
      v-if="drawershow"
    >
      <div class="main1 main-box">
        <div class="main1-content">
          <div>
            <span>
              <router-link to="/home/home6">{{ name }}</router-link>
            </span>&nbsp;&nbsp;&nbsp;
            <span>NEWS CENTER</span>
          </div>
          <div>
            <span>
              <router-link to="/">首页</router-link>&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;
            </span>
            <span>
              <router-link to="/home/home6">{{ name }}</router-link>
            </span>
          </div>
        </div>
      </div>
      <router-view></router-view>

      <div class="main3 main-box">
        <div class="main3-content">
          <p>致电400-105-1009获取您的专属解决方案</p>
          <p>
            丰富的行业纵深，无论您的企业处在什么样的发展阶段，都可以获得相应的方案设计
          </p>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <!-- <div
      class="main-mobile"
      v-else
    ></div> -->
  </div>
</template>

<script>
import store from "../../store";
export default {
  props: ["name"],

  data () {
    return {
      drawershow: sessionStorage.getItem("drawershow"),
    };
  },
  beforeCreate () {
    const that = this;
    if (sessionStorage.getItem("drawershow")) {
      //当他有值的时候
      console.log(1);
    } else {
      //当他没有值的时候
      window.screenWidth = document.documentElement.clientWidth;
      if (window.screenWidth >= 992) {
        this.resetSetItem("drawershow", true);

        store.commit("SET_DRAWERSHOW", true);
      } else if (that.screenWidth < 992) {
        this.resetSetItem("drawershow", false);

        store.commit("SET_DRAWERSHOW", false);
      }
    }
  },
  created () {
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });
  },
};
</script>

<style lang="less" scoped>
.main-pc {
  width: 100%;
  height: auto;
  background: #fff;
  .main-box {
    width: 60.2rem;
    margin: auto;
  }
  .main1 {
    width: 100%;
    height: 6rem;
    background: #f8f8f8;
    .main1-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      // background: crimson;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(1) {
        span:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 550;
          letter-spacing: 1px;
          a {
            text-decoration: none;
            color: black;
          }
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          letter-spacing: 1px;
        }
      }
      div:nth-child(2) {
        span:nth-child(1) {
          font-size: 0.7rem;
          letter-spacing: 1px;
          a {
            color: black;
          }
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          font-weight: 550;
          color: #0b4696;
          letter-spacing: 1px;
          a {
            text-decoration: none;
            color: #0b4696;
          }
        }
      }
    }
  }

  .main3 {
    height: 11rem;
    width: 100%;
    background: #f8f8f8;
    .main3-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      p:nth-child(1) {
        font-size: 1.5rem;
        // font-weight: 600;
        // line-height: 4vw;
      }
      p:nth-child(2) {
        font-size: 0.8rem;
        letter-spacing: 0.2rem;
        margin-top: 1rem;
        color: #848484;
      }
    }
  }
}
</style>
