import request from "@/until/request";

export const getList = (params) => {
  return request({
    // url: '/api/blade-demo/policy/list',
    url: "/api/blade-demo/policy/frontList",
    method: "get",
    params: {
      ...params,
      current: 1,
      size: 10,
    },
  });
};
export const getOne = (type) => {
  return request({
    // url: '/api/blade-demo/policy/list',
    url: "/api/blade-demo/policy/one",
    method: "get",
    params: {
      type,
    },
  });
};
export const getOneById = (id) => {
  return request({
    // url: '/api/blade-demo/policy/list',
    url: "/api/blade-demo/policy/detail",
    method: "get",
    params: {
      id: id,
    },
  });
};
export const getBackList = (current, size, params) => {
  return request({
    // url: '/api/blade-demo/policy/list',
    url: "/api/blade-demo/policy/backList",
    method: "get",
    params: {
      ...params,
      current,
      size,
    },
  });
};
export const getDetail = (id) => {
  return request({
    url: "/api/blade-demo/policy/detail",
    method: "get",
    params: {
      id,
    },
  });
};

export const remove = (ids) => {
  return request({
    url: "/api/blade-demo/policy/remove",
    method: "post",
    params: {
      ids,
    },
  });
};

export const add = (row) => {
  return request({
    url: "/api/blade-demo/policy/submit",
    method: "post",
    data: row,
  });
};

export const update = (row) => {
  return request({
    url: "/api/blade-demo/policy/submit",
    method: "post",
    data: row,
  });
};

export const changeTop = (id) => {
  return request({
    url: "/api/blade-demo/policy/changeTop",
    method: "get",
    params: {
      id: id,
    },
  });
};

export const policyone = (type) => {
  return request({
    url: "/api/blade-demo/policy/one",
    method: "get",
    params: {
      type: type,
    },
  });
};

export const main = () => {
  return request({
    url: "/api/blade-demo/policy/main",
    method: "get",
  });
};

export const getTop = () => {
  return request({
    url: "/api/blade-demo/policy/getTop",
    method: "get",
  });
};

export const newsList = () => {
  return request({
    url: "/api/blade-demo/policy/newsList",
    method: "get",
  });
};

export const apply = (id, isPass, noRemark) => {
  return request({
    url: "/api/blade-demo/policy/apply",
    method: "post",
    params: {
      id,
      isPass,
      noRemark,
    },
  });
};

export const changeType = (ids, category2) => {
  return request({
    url: "/api/blade-demo/policy/changeTypes",
    method: "get",
    params: {
      ids,
      category2,
    },
  });
};

// 获取待审核
export const shNum = () => {
  return request({
    url: "/api/blade-demo/policy/getApplyNum",
    method: "post",
  });
};
