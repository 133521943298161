<template>
  <div class="main">
    <div class="text">
      <h1>技术支持</h1>
      <div>
        <p>
          宁明软件的服务团队致力于保障客户产品的稳定高效运行。我们的服务工程师和临床应用专家将帮助您安置和培训操作人员，保证系统正常运行。
        </p>
        <p>如果遇到问题需要帮助,我们的团队会第一时间帮您解决。</p>
        <p style="font-weight: 550">电话支持</p>
        <p>宁明软件为您提供即时的电话协助，快速就地解决您的问题。</p>
        <p style="font-weight: 550">远程服务</p>
        <p>
          宁明软件为您提供远程服务，无需登门即可快速诊断系统问题（即查看图像、下载系统图像和错误日志)。这样可以实现问题的早期检测，将故障停机几率降至最低。
        </p>
        <p style="font-weight: 550">升级服务</p>
        <p>
          宁明软件免费提供基础软件升级服务，保持设备始终处于最新状态，延长您的初始投资周期。我们也可提供硬件升级，需视情收费。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>
<style lang="less" scoped>
.main {
  .text {
    width: 85%;
    margin: 1rem auto;
    h1 {
      font-size: 1.5rem;
      text-align: center;
      line-height: 5rem;
    }
    div {
      margin-bottom: 1rem;
      h3 {
        font-size: 1rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.788);
      }
      p {
        font-size: 0.9rem;
        line-height: 2rem;
        color: rgba(96, 96, 102, 0.664);
        font-family: "Helvetica Neue", Helvetica, "PingFang SC",
          "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
        margin-bottom: 1rem;
      }
      img {
        display: block;
        width: 100%;
        margin: auto;
      }
    }
  }
}
</style>
