<template>
  <div class="main">
    <!-- pc端 -->
    <div
      class="main-pc"
      v-if="drawershow == 'true'"
    >
      <div class="main1 main-box">
        <div class="main1-content">
          <div>
            <span>{{ name }}</span>&nbsp;&nbsp;&nbsp;
            <span>CONTACT US</span>
          </div>
          <div>
            <span>
              <router-link to="/">首页</router-link>&nbsp;&nbsp;&nbsp;>&nbsp;&nbsp;&nbsp;
            </span>
            <span>{{ name }}</span>
          </div>
        </div>
      </div>

      <div class="main2 main-box">
        <div class="img"></div>
        <div
          class="text"
          v-html="clent"
        ></div>
      </div>

      <div class="main3 main-box">
        <div class="main3-content">
          <p>致电400-105-1009获取您的专属解决方案</p>
          <p>
            丰富的行业纵深，无论您的企业处在什么样的发展阶段，都可以获得相应的方案设计
          </p>
        </div>
      </div>
    </div>
    <!-- 手机端 -->
    <div
      class="main-mobile"
      v-else
    >
      <div class="main2 main-box">
        <div class="img"></div>
        <div class="textBox">
          <div
            class="text"
            v-html="clent"
          ></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getOne } from "@/api/policy";

export default {
  props: ["name"],
  data () {
    return {
      clent: "",
      drawershow: sessionStorage.getItem("drawershow"), //是否打开pc端样式
    };
  },
  created () {
    getOne(9).then((res) => {
      this.clent = res.data.content;
    });
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });
  },
};
</script>

<style lang="less" scoped>
.main-pc {
  width: 100%;
  height: auto;
  background: #fff;
  .main-box {
    width: 60.2rem;
    margin: auto;
  }
  .main1 {
    width: 100%;
    height: 6rem;
    background: #f8f8f8;
    .main1-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      background: #f8f8f8;
      display: flex;
      justify-content: space-between;
      align-items: center;
      div:nth-child(1) {
        span:nth-child(1) {
          font-size: 1.5rem;
          font-weight: 550;
          letter-spacing: 1px;
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          letter-spacing: 1px;
        }
      }
      div:nth-child(2) {
        span:nth-child(1) {
          font-size: 0.7rem;
          letter-spacing: 1px;
          a {
            color: black;
          }
        }
        span:nth-child(2) {
          font-size: 0.7rem;
          font-weight: 550;
          color: #0b4696;
          letter-spacing: 1px;
        }
      }
    }
  }

  .main2 {
    height: auto;
    background: #fff;
    padding-top: 2.8rem;
    padding-bottom: 9.4rem;
    // border: 1px solid rgba(218, 224, 233, 1);
    .img {
      width: 100%;
      height: 19.75rem;
      background: url("../../assets/img/z1.png") no-repeat;
      background-size: 100%;
    }

    /deep/.text {
      width: 100%;
      height: 30rem;
      // background: oldlace;
      padding: 4rem 12.5rem 5rem 12.5rem;
      display: flex;
      justify-content: space-between;
      color: rgba(132, 132, 132, 1);
      font-size: 14px;
      .left {
        width: auto;
        height: 100%;
        // background: rgb(184, 241, 77);
        line-height: 2.5rem;
      }
      .right {
        width: auto;
        height: 100%;
        // background: rgb(106, 248, 137);
        line-height: 2.5rem;
      }
    }
  }
  .main3 {
    height: 11rem;
    width: 100%;
    background: #f8f8f8;
    .main3-content {
      width: 60.2rem;
      height: 100%;
      margin: auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #f8f8f8;
      p:nth-child(1) {
        font-size: 1.5rem;
        // font-weight: 600;
        // line-height: 4vw;
      }
      p:nth-child(2) {
        font-size: 0.8rem;
        letter-spacing: 0.2rem;
        margin-top: 1rem;
        color: #848484;
      }
    }
  }
}
.main-mobile {
  .main2 {
    height: auto;
    background: #fff;
    // padding-top: 1rem;
    padding-bottom: 2.8rem;
    // border: 1px solid rgba(218, 224, 233, 1);
    .img {
      width: 100%;
      height: 35vw;
      background: url("../../assets/img/z1.png") no-repeat;
      background-size: 100%;
      // border: 1px solid black;
    }
    .textBox {
      padding: 10px 0;
      display: flex;
      justify-content: space-around;
    }
    /deep/.text {
      width: auto;
      height: auto;
      // background: oldlace;
      // padding: 4rem 12.5rem 5rem 12.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: rgba(132, 132, 132, 1);
      font-size: 13px;
      .left {
        width: auto;
        height: 100%;
        // background: rgb(184, 241, 77);
        line-height: 2.5rem;
        padding: 0 4rem;
      }
      .right {
        width: auto;
        height: 100%;
        // background: rgb(106, 248, 137);
        line-height: 2.5rem;
        padding: 0 4rem;
      }
    }
  }
}
</style>
