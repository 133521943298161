<template>
  <div class="home">
    <Header />
    <Nav />
    <!-- <keep-alive> -->
    <router-view></router-view>
    <!-- :name="name" -->
    <!-- </keep-alive> -->
    <Footer />
  </div>
</template>

<script>
import Header from "../components/Home/Header.vue";
import Nav from "../components/Home/Nav.vue";
import Footer from "../components/Home/Footer.vue";
import store from "../store";
export default {
  // name: "Home",
  data () {
    return {
      drawershow: true,
    };
  },
  components: {
    Header,
    Nav,
    Footer,
  },

  mounted () {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.documentElement.clientWidth;
        that.screenWidth = window.screenWidth;
        if (that.screenWidth >= 992) {
          that.drawershow = true;
          this.resetSetItem("drawershow", that.drawershow);

          store.commit("SET_DRAWERSHOW", that.drawershow);

          sessionStorage.setItem("drawershow", that.drawershow);
        } else if (that.screenWidth < 992) {
          that.drawershow = false;
          this.resetSetItem("drawershow", that.drawershow);

          store.commit("SET_DRAWERSHOW", that.drawershow);

          sessionStorage.setItem("drawershow", that.drawershow);
        }
        // console.log(this.drawershow, "7878787878787");
      })();
    };
  },
};
</script>
