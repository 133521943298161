import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    nav: "",
    drawershow: sessionStorage.getItem("drawershow") || true,
  }, //2021/11/18晚上研究的代码
  mutations: {
    SET_DRAWERSHOW (state, show) {
      state.drawershow = show;
    },
  },
  actions: {},
  modules: {},
});
