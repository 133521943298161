<template>
  <div class="main main-box">
    <!-- pc端 -->
    <div
      class="main-pc"
      v-if="drawershow == 'true'"
    >
      <div class="outerFrame">
        <img
          :src="news.tiltePic"
          alt=""
          style=""
        />
      </div>

      <div class="text-content">
        <h1>{{ news.title }}</h1>
        <p>{{ news.createTime }} &nbsp;&nbsp;| &nbsp;&nbsp;宁明编辑部</p>
        <span>
          <div v-html="news.content"></div>
        </span>
      </div>
    </div>
    <!-- 手机端 -->
    <div
      class="main-mobile"
      v-else
    >
      <div class="outerFrame">
        <img
          :src="news.tiltePic"
          alt=""
          style=""
        />
      </div>

      <div class="text-content">
        <h1>{{ news.title }}</h1>
        <p>{{ news.createTime }} &nbsp;&nbsp;| &nbsp;&nbsp;宁明编辑部</p>
        <span>
          <div v-html="news.content"></div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { getOneById } from "@/api/policy";

export default {
  data () {
    return {
      news: {},
      drawershow: sessionStorage.getItem("drawershow"),
    };
  },
  created () {
    window.addEventListener("setItem", () => {
      this.drawershow = sessionStorage.getItem("drawershow");
    });
    getOneById(this.$route.query.id).then((res) => {
      this.news = res.data;
      console.log(res);
    });
  },
};
</script>

<style lang="less" scoped>
.main-pc {
  height: auto;
  background: #fff;
  padding-top: 3.5rem;
  padding-bottom: 5rem;
  .outerFrame {
    width: 100%;
    height: 25rem;
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    img {
      width: 100%;
      // margin-bottom: 30px;
    }
  }

  .text-content {
    // background: red;
    h1 {
      text-align: center;
      line-height: 3rem;
      font-size: 1.5rem;
    }
    p {
      text-align: center;
      line-height: 2rem;
      color: rgba(132, 132, 132, 1);
    }
    /deep/span {
      p {
        color: rgba(132, 132, 132, 1);
      }
    }
    // span {
    //   display: block;
    //   margin-bottom: 40px;
    //   color: rgba(132, 132, 132, 1);
    //   h3 {
    //     font-size: 13px;
    //     font-weight: 600;
    //   }
    //   p {
    //     font-size: 13px;
    //     text-align: left;
    //     line-height: 30px;
    //   }
    // }
  }
}
.main-mobile {
  .outerFrame {
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    // margin-bottom: 1.5rem;
    img {
      width: 100%;
      // margin-bottom: 30px;
    }
  }

  .text-content {
    width: 100%;
    // border: 1px solid black;
    padding: 1rem 2rem 1rem 2rem;
    // background: red;
    h1 {
      text-align: center;
      line-height: 3rem;
      font-size: 2rem;
    }
    p {
      text-align: center;
      line-height: 2rem;
      color: rgba(132, 132, 132, 1);
    }
    /deep/span {
      p {
        color: rgba(132, 132, 132, 1);
      }
    }
  }
}
</style>
